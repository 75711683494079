import React, {useState, useEffect, Fragment} from 'react';
import Modal from "react-modal";
import useForm from '../../hooks/useForm';
import { IoIosClose } from "react-icons/io";
import {clienteAxios} from '../../config/axios';
import useSelector from '../../hooks/useSelector';
import Grid from '@mui/material/Grid';
import "./modals.css"
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
var FormData = require('form-data');

const ClientModal = ({isOpen, onRequestClose, companyData, onSave}) => {

    const [listaEntity, setListaEntity] = useState([])
    const [id_entity, SelectEntity, setIdEntity] = useSelector('', '', listaEntity);
    const [isEmpresa, setIsEmpresa] = useState(false);
    const [errors, setErrors] = useState([])

    const { values, handleChange } = useForm({
		initialValues: {
			name: companyData ? companyData.name : '',
			id_type: companyData ? companyData.id_type : '',
			id_number: companyData ? companyData.id_number : '',
			type: companyData ? companyData.type_id : '',
            street_name: companyData ? companyData.street_name : '',
            postal_code: companyData ? companyData.postal_code : '',
            province: companyData ? companyData.province : '',
            city: companyData ? companyData.city : '',
            agent_name: '',
            agent_id_number: '',
            country: 'España'
		}
	});

    useEffect(() => {

        const getEntities = async () => {
            let urlLocalizacion1 = 'compute/getEntities';
            try {
                const entity = await clienteAxios.get(urlLocalizacion1);
                setListaEntity(entity.data);
                if (entity.data.length == 1) {
                    setIdEntity(entity.data[0].id)
                }

                if (companyData) {
                    setIdEntity(companyData.entity_id)
                }

            } catch (error) {
                console.log(error);
                setListaEntity([]);
            }
        }

        getEntities()

    }, [])

    useEffect(() => {
        if (values.type == '1') {
            setIsEmpresa(true)
        } else {
            setIsEmpresa(false)
        }
        
    }, [values.type])

    const handleClose = () => { onRequestClose(); };

    const handleValidation = () => {
        let errors = [];
        let formIsValid = true;

        if (! (id_entity && values.name && values.id_type && values.id_number && values.type && values.street_name && values.postal_code && values.province && values.city)) {
            formIsValid = false;
            errors.push("Todos los campos son obligatorios");
        }

        if (isEmpresa && !(values.agent_name && values.agent_id_number)) {
            formIsValid = false;
            errors.push("Necesarios los datos del representante");
        }

        setErrors(errors)
   
        return formIsValid;
    }


    const handleCreate = (e) => {
        e.preventDefault();
        let valid = handleValidation()
        if (valid) {
            var data = new FormData();
            Object.entries(values).forEach(([key, value]) => {
                console.log(key + ' ' + value);
                data.append(key, value);
            });
    
            if (companyData) {
                data.append('id', companyData.id)
                //data.append('type_id', companyData.type_id)
            }
    
            data.append('entity', id_entity)
    
            var config = {
                method: 'post',
                url: '',
                data : data
            };
    
            config.url = companyData ? 'compute/updateCompany' : 'compute/createCompany'
              
              clienteAxios(config)
              .then((result) => {
                alert('Elemento guardado');
                handleClose()
                onSave(true);
            })
            .catch((error) => {
                console.log(error);
                alert('Se ha producido un error')
            })
        }
    }

    return (  
        <Modal isOpen={isOpen} onRequestClose={handleClose} >
            <form className='formModal' onSubmit={handleCreate}>
                <div className='closeIcon'>
                    <IoIosClose onClick={handleClose} />
                </div>
        
                <div className='modalContainer'>
                    <h2>{companyData ? 'Editar' : 'Añadir'} cliente</h2>
                    {
                        errors.length > 0 && 
                        <Stack sx={{ width: '100%' }} spacing={1}>
                            {
                                errors.map((error) => {
                                    return (
                                        <Alert severity="error">{error}</Alert>
                                    )
                                })
                            }
                        </Stack>
                    }
                    <div>
                        <label>Organización</label>
                        <Grid item><div ><SelectEntity/></div></Grid>
                    </div>

                    <div>
                        <label>Tipo ID</label>
                        <select id="id_type" defaultValue={''} name ="id_type" onChange={handleChange} value={values.id_type}>
                            <option value='' disabled="disabled">---</option> 
                            <option value='1'>CIF</option>
                            <option value="2">NIF</option>
                            <option value="3">NIE</option>
                        </select>
                    </div>
                    <div>
                        <label>ID</label>
                        <input 
                            type="text"
                            name="id_number"
                            className="form-control"
                            onChange={handleChange}
                            value={values.id_number}>
                        </input>
                    </div>
                    <div>
                        <label>Tipo</label>
                        <select id="type" defaultValue={''} name ="type" onChange={handleChange} value={values.type}>
                            <option value='' disabled="disabled">---</option> 
                            <option value='1'>Empresa</option>
                            <option value="2">Autónomo</option>
                            <option value="3">Particular</option>
                        </select>
                    </div>
                    <div>
                        <label>Nombre {isEmpresa ? ' Empresa' : ''}</label>
                        <input 
                            type="text"
                            name="name"
                            className="form-control"
                            onChange={handleChange}
                            value={values.name}>
                        </input>
                    </div>
                    { isEmpresa &&
                        <Fragment>
                        <div>
                            <label>Nombre representante</label>
                            <input 
                                type="text"
                                name="agent_name"
                                className="form-control"
                                onChange={handleChange}
                                value={values.agent_name}>
                            </input>
                        </div>
                        <div>
                            <label>NIF representante</label>
                            <input 
                                type="text"
                                name="agent_id_number"
                                className="form-control"
                                onChange={handleChange}
                                value={values.agent_id_number}>
                            </input>
                        </div>
                        </Fragment>
                    }
                    <div>
                        <label>Dirección</label>
                        <input 
                            type="text"
                            name="street_name"
                            className="form-control"
                            onChange={handleChange}
                            value={values.street_name}>
                        </input>
                    </div>
                    <div>
                        <label>Código postal</label>
                        <input 
                            type="text"
                            name="postal_code"
                            className="form-control"
                            onChange={handleChange}
                            value={values.postal_code}>
                        </input>
                    </div>
                    <div>
                        <label>Provincia</label>
                        <input 
                            type="text"
                            name="province"
                            className="form-control"
                            onChange={handleChange}
                            value={values.province}>
                        </input>
                    </div>
                    <div>
                        <label>Ciudad</label>
                        <input 
                            type="text"
                            name="city"
                            className="form-control"
                            onChange={handleChange}
                            value={values.city}>
                        </input>
                    </div>
                </div>
                
                <div className='addModalBtn'>
                    <button className='btn' type='submit'>{companyData ? 'Actualizar' : 'Añadir'}</button>
                </div>
            </form>
        </Modal>
    );
}

 
export default ClientModal;

import {React} from 'react';
import './App.css';
import Login from './components/login/Login'
import Terms from './components/login/Terms'
import ForgotPassword from './components/login/ForgotPassword'
import ResetPassword from './components/login/ResetPassword'
import UpdatePassword from './components/login/UpdatePassword'
import ActivateAccount from './components/login/ActivateAccount'
import Menu from './components/menu/Menu'
import Documents from './components/documents/Documents'
import AddDocuments from './components/addDocuments/AddDocuments'
import CustomerManagement from './components/customerManagement/CustomerManagement'
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom'; 
import { UserContext } from './hooks/UserContext';
import useFindUser from './hooks/useFindUser';
import PrivateRoute from './components/PrivateRoute';
import ValidateDocs from './components/validateDocs/ValidateDocs'
import ValidateDocsList from './components/validateDocsList/ValidateDocsList'
import MenuBar from './components/menuBar/MenuBar'
import CustomerVehicle from './components/customerVehicle/CustomerVehicle'
import FrequentQuestions from './components/frequentQuestions/FrequentQuestions'
import Reporting from './components/reporting/Reporting'


function App() {

	const { user, setUser, isLoading } = useFindUser();

  	return (
		<Router>
			<UserContext.Provider value={{ user, setUser, isLoading }}>
				<Routes>
					<Route path="/terms" element={<PrivateRoute><Terms /></PrivateRoute>}/>
					<Route path="/login" element={<Login />}/>
					<Route path="/forgot-password" element={<ForgotPassword />}/>
					<Route path="/reset-password/:uid/:key" element={<ResetPassword />}/>
					<Route path="/activate-account/:uid/:key" element={<ActivateAccount />}/>
					<Route path="/update-password" element={<UpdatePassword />}/>
					<Route path="*" element={ <main style={{ padding: "1rem" }}> <p>There's nothing here!</p></main>}/>
					<Route element = {<MenuBar />} >
						<Route path="/" element={<PrivateRoute><Menu /></PrivateRoute>}/>
						<Route path="documents" element={<PrivateRoute><Documents /></PrivateRoute>}/>
						<Route path="add-documents" element={<PrivateRoute><AddDocuments /></PrivateRoute>} />
						<Route path="customerManagement" element={ <PrivateRoute> <CustomerManagement /> </PrivateRoute>}/>
						<Route path="validatedocs/" element={ <PrivateRoute only_admin={true}><ValidateDocsList /></PrivateRoute>}/>
						<Route path="validatedocs/:company_id/" element={<PrivateRoute only_admin={true}><ValidateDocs /></PrivateRoute>}/>
            			<Route path='/customerVehicle/:id' element={ <PrivateRoute><CustomerVehicle/> </PrivateRoute>}/>
						<Route path='/frequentQuestions' element={ <PrivateRoute><FrequentQuestions/> </PrivateRoute>}/>
						<Route path='/reporting' element={ <PrivateRoute><Reporting/> </PrivateRoute>}/>
					</Route>
				</Routes>
			</UserContext.Provider>
		</Router>
   	);
}

export default App;
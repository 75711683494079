import { useState, useEffect } from 'react';
import {clienteAxios} from '../config/axios'

export default function useFindUser() {
    const [user, setUser] = useState(null);
    const [isLoading, setLoading] = useState(true);

    useEffect(() =>{
        async function findUser() {
            await clienteAxios.get('auth/userdata')
            .then(res => {
                setUser(res.data.result);
                localStorage.setItem("userDataArray", JSON.stringify(res.data.result));
                setLoading(false);
            }).catch(err => {
                console.log(err)
                setLoading(false);
            });
        }

        if (localStorage.getItem('userDataArray')) {
            setUser(JSON.parse(localStorage.getItem('userDataArray')));
        } else if (localStorage.getItem("token_access")) {
            findUser();
        }

    }, []);
    
    return {
        user,
        setUser,
        isLoading
    }
}
import React, { useEffect, useState, Fragment } from 'react';
import FileCard from './FileCard';
import Grid from '@mui/material/Grid';


const CarFiles = ({uploadedList, car, document_type_name, document_type_id  }) => {

    const [hasFile, setHasFile] = useState(new Map())
        
    useEffect(() => {
        let obj = new Map();

        for (let i = 0; i < uploadedList.length; i ++) {
            let type = uploadedList[i].document_species_id
            let file = {id: uploadedList[i].id, name : uploadedList[i].file + " (Subido el " + uploadedList[i].ts_upload + ')'}

            if (!obj.has(type)) obj.set(type, [])
            obj.get(type).push(file)
        }

        setHasFile(obj);

	}, [uploadedList]);

    return (
		<Fragment >
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
                aligncontent="flex-start"
                spacing={1}
                className='optionsAddDocumentSelect'
            >

            <div className='filesCard'>
                <FileCard 
                    car = {car}
                    document_type_name = {document_type_name}
                    document_type_id = {document_type_id}
                    file = {hasFile.has(parseInt(document_type_id)) ? hasFile.get(parseInt(document_type_id)) : []}
                    
                />
            </div>
            
            </Grid>
        </Fragment>
	  );
	};


export default CarFiles
import React, { useEffect, useState, useMemo, Fragment } from 'react';
import useForm from '../../hooks/useForm';
import { Tabs } from "@feuer/react-tabs";
import AnyChart from 'anychart-react'
import anychart from "anychart";
import {clienteAxios} from '../../config/axios';
import useSelector from '../../hooks/useSelector';
import Grid from '@mui/material/Grid';
import "./reporting.css"

const Reporting = (props) => {

	//filtros
	const [listaMarcas, setListaMarcas] = useState([])
    const [listaEntity, setListaEntity] = useState([])
	const [listaCompany, setListaCompany] = useState([]);
    const [id_marca, SelectMarca, setIdMarca] = useSelector('', '', listaMarcas);
    const [id_entity, SelectEntity, setIdEntity] = useSelector('', '', listaEntity);
	const [id_company, SelectCompany, setIdCompany] = useSelector('', '', listaCompany);

	//datos de las gráficas
	const [dataClientes, setDataClientes] = useState([])
	const [dataVehiculos, setDataVehiculos] = useState([])
	const [dataVehiculosPorMarca, setDataVehiculosPorMarca] = useState([])
	const [dataBarras, setDataBarras] = useState([])
	const [dataProcessedBarras, setProcesedDataBarras] = useState([])

	const [dataProcessedClientes, setDataProcessedClientes] = useState([])
	const [dataProcessedVehiculos, setDataProcessedVehiculos] = useState([])
	const [dataProcessedVehiculosPorMarca, setDataProcessedVehiculosPorMarca] = useState([])

	const { values, handleChange, setValues } = useForm({
		initialValues: {
			entity_id: '',
			company_id: '',
			year: '',
			brand_id: '',
			closed: '',
			verified: ''
		}
	});

	useEffect(() => { setValues({...values, ['entity_id']: id_entity}); }, [id_entity])
	useEffect(() => { setValues({...values, ['brand_id']: id_marca}); }, [id_marca])
	useEffect(() => { setValues({...values, ['company_id']: id_company}); }, [id_company])

	const getClientsData = async () => {
		let urlLocalizacion1 = 'compute/reportingClientData';
		try {
			const data = await clienteAxios.get(urlLocalizacion1, {params: values});
			setDataClientes(data.data)
		} catch (error) {
			console.log(error);
		}
	}

	const getClientsCarData = async () => {
		let urlLocalizacion1 = 'compute/reportingClientCarData';
		try {
			const data = await clienteAxios.get(urlLocalizacion1, {params: values});
			setDataVehiculos(data.data)
		} catch (error) {
			console.log(error);
		}
	}

	const getReportingCarData = async () => {
		let urlLocalizacion1 = 'compute/reportingCarData';
		try {
			const data = await clienteAxios.get(urlLocalizacion1, {params: values});
			setDataBarras(data.data)
			console.log(data)

		} catch (error) {
			console.log(error);
			setDataBarras([])
		}
	}

	const getCarsByBrand = async () => {
		let urlLocalizacion1 = 'compute/reportingCarsByBrand';
		try {
			const data = await clienteAxios.get(urlLocalizacion1, {params: values});
			setDataVehiculosPorMarca(data.data)
		} catch (error) {
			console.log(error);
			setDataVehiculosPorMarca([])
		}
	}

	const getProcessedCarsByBrand = async () => {
		let urlLocalizacion1 = 'compute/reportingProcessedCarsByBrand';
		try {
			const data = await clienteAxios.get(urlLocalizacion1, {params: values});
			setDataProcessedVehiculosPorMarca(data.data)
		} catch (error) {
			console.log(error);
			setDataProcessedVehiculosPorMarca([])
		}
	}

	const getProcessedClients = async () => {
		let urlLocalizacion1 = 'compute/reportingProcessedClients';
		try {
			const data = await clienteAxios.get(urlLocalizacion1, {params: values});
			setDataProcessedClientes(data.data)
		} catch (error) {
			console.log(error);
			setDataProcessedClientes([])
		}
	}

	const getProcessedCars = async () => {
		let urlLocalizacion1 = 'compute/reportingProcessedCars';
		try {
			const data = await clienteAxios.get(urlLocalizacion1, {params: values});
			setDataProcessedVehiculos(data.data)
		} catch (error) {
			console.log(error);
			setDataProcessedVehiculos([])
		}
	}

	const getProcessedReportingCarData = async () => {
		let urlLocalizacion1 = 'compute/reportingProcessedCarData';
		try {
			const data = await clienteAxios.get(urlLocalizacion1, {params: values});
			setProcesedDataBarras(data.data)
			console.log(data)

		} catch (error) {
			console.log(error);
			setProcesedDataBarras([])
		}
	}

	//effect inicial
	useEffect(() => {
        const getBrands = async () => {
            try {
                const result = await clienteAxios.get('compute/getBrands');
                setListaMarcas(result.data);
            } catch (error) {
                console.log(error);
            }
        };

        const getEntities = async () => {
            let urlLocalizacion1 = 'compute/getEntities';
            try {
                const entity = await clienteAxios.get(urlLocalizacion1);
                setListaEntity(entity.data);
                if (entity.data.length == 1) {
                    setIdEntity(entity.data[0].id)
                }

            } catch (error) {
                console.log(error);
                setListaEntity([]);
            }
        }

        getEntities()
        getBrands();

		getClientsData();
		getClientsCarData();
		getCarsByBrand();

		getProcessedCarsByBrand();
		getProcessedClients();
		getProcessedCars();
    }, [])

	//lista de compañias
	useEffect(() => {
		const getCompanies = async () => {
			let urlLocalizacion1 = `compute/getEntityCompanies/${id_entity}`;
			try {
				const companies = await clienteAxios.get(urlLocalizacion1);

				for (let i = 0; i < companies.data.length; i++) {
					delete companies.data[i].disabled
				}

				setListaCompany(companies.data);
			} catch (error) {
				console.log(error);
				setListaCompany([]);
			}
		}

		setIdCompany('')
		if (id_entity.length !== 0) getCompanies()
	
	}, [id_entity]);

	//si se modifican los filtros
	useEffect(() => {
		if (id_company == '') {getClientsData(); getProcessedClients();}
		getClientsCarData();
		getProcessedCars();
		if (id_marca != '') {getReportingCarData(); getProcessedReportingCarData();}
		getCarsByBrand();
		getProcessedCarsByBrand();
	
	}, [values]);


//generación de gráficas
	//---------------------------------------------------------Pie charts clientes
	var pieChart1 = anychart.pie();

	pieChart1.data([
		["Cerrados", dataClientes.closed],
		["Pendientes de cerrar", dataClientes.open],
	]);

  	pieChart1.title("Clientes cerrados");

	var pieChart2 = anychart.pie();
	pieChart2.data([
		["Verificados", dataClientes.verified],
		["Pendientes de verificar", dataClientes.verified_pending],
	]);

	// set chart title
	pieChart2.title("Clientes verificados");

	var pieChartClientesProcesados = anychart.pie();
	pieChartClientesProcesados.data([
		["Procesados", dataProcessedClientes.processed],
		["Pendientes de procesar", dataProcessedClientes.pending],
	]);

	// set chart title
	pieChartClientesProcesados.title("Clientes procesados");

	//---------------------------------------------------------pie charts vehículos
	var pieChart3 = anychart.pie();
	pieChart3.data([
	["Cerrados", dataVehiculos.closed],
	["Pendientes de cerrar", dataVehiculos.open],
	]);

	pieChart3.title("Clientes cerrados");

	var pieChart4 = anychart.pie();
	pieChart4.data([
		["Verificados", dataVehiculos.verified],
		["Pendientes de verificar", dataVehiculos.verified_pending],
	]);

	pieChart4.title("Clientes verificados");

	var pieChartCochesProcesados = anychart.pie();
	pieChartCochesProcesados.data([
	["Procesados", dataProcessedVehiculos.processed],
	["Pendientes de procesar", dataProcessedVehiculos.pending],
	]);

	pieChartCochesProcesados.title("Clientes cerrados");

//---------------------------------------------------------	gráfica columnas
	var data = anychart.data.set(dataBarras);
	// map the data
	var seriesData_1 = data.mapAs({x: 0, value: 1});
	var seriesData_2 = data.mapAs({x: 0, value: 2});

	// create a chart
	var chart = anychart.column();

	// enable the value stacking mode
	chart.yScale().stackMode("value");

	// create column series
	var series1 = chart.column(seriesData_1);
	var series2 = chart.column(seriesData_2);

	// configure tooltips
	chart.tooltip().format("{%value} ({%yPercentOfCategory}{decimalsCount:2}%)");

	// configure labels on the y-axis
	chart.yAxis().labels().format("{%value}");

//---------------------------------------------------------	gráfica columnas procesados
var data = anychart.data.set(dataProcessedBarras);
// map the data
var seriesData_1 = data.mapAs({x: 0, value: 1});
var seriesData_2 = data.mapAs({x: 0, value: 2});

// create a chart
var chartProcessed = anychart.column();

// enable the value stacking mode
chartProcessed.yScale().stackMode("value");

// create column series
var series1 = chartProcessed.column(seriesData_1);
var series2 = chartProcessed.column(seriesData_2);

// configure tooltips
chartProcessed.tooltip().format("{%value} ({%yPercentOfCategory}{decimalsCount:2}%)");

// configure labels on the y-axis
chartProcessed.yAxis().labels().format("{%value}");

return (
	<Tabs
		tabsProps={{style: {textAlign: "center", fontSize:18}}}
		activeTab={{id: "informes"}}
	>
		<Tabs.Tab id="informes" title="Informes">
			<div style={{ padding: 10 }}>
				<h2 className='titReport'>Reporting</h2>
				<div className='tabsReport'>
					<div>
						<label>Organización</label>
						<SelectEntity/>	
					</div>
					<div>
						<label>Empresa</label>
						<SelectCompany/>
					</div>
					<div>
						<label>Año de compra</label>
						<select id="year" defaultValue={''} name ="year" onChange={handleChange} value={values.year}>
							<option value=''>TODOS</option>
							<option value="2000">2000</option>
							<option value="2001">2001</option>
							<option value='2002'>2002</option>
							<option value="2003">2003</option>
							<option value="2004">2004</option>
							<option value='2005'>2005</option>
							<option value='2006'>2006</option>
							<option value="2007">2007</option>
							<option value="2008">2008</option>
							<option value="2009">2009</option>
							<option value='2010'>2010</option>
							<option value="2011">2011</option>
							<option value="2012">2012</option>
							<option value='2013'>2013</option>
							<option value="2014">2014</option>
							<option value='2015'>2015</option>
							<option value='2016'>2016</option>
							<option value="2017">2017</option>
							<option value="2018">2018</option>
							<option value="2019">2019</option>
							<option value='2020'>2020</option>
							<option value="2021">2021</option>
							<option value="2022">2022</option>
						</select>
					</div>
					<div>
						<label>Marca</label>
						<Grid item><div ><SelectMarca/></div></Grid>
					</div>
				</div>

				{
				id_company == '' &&
				<div className='contReport'>
					<div>
						<h3>Clientes</h3>
						<div className='containerTable tableReporting'>
							<div>
								<table className='tableClient'>
									<tr>
										<td>Cerrado</td>
										<td>{dataClientes.closed}</td>
									</tr>
									<tr>
										<td>Pendientes de cerrar</td>
										<td>{dataClientes.open}</td>
									</tr>

									<tr>
										<th>Total clientes</th>
										<th>{dataClientes.closed + dataClientes.open}</th>
									</tr>
								</table>
							</div>
							<div className='pieChartStyle'>
								<AnyChart
									id="pieChartClientClose"
									width={350}
									height={250}
									instance={pieChart1}
									title="Número de clientes cerrados"
								/>
							</div>
							<div>
								<table className='tableClient'>
									<tr>
										<td>Verificados</td>
										<td >{dataClientes.verified}</td>
									</tr>
									<tr>
										<td>Pendientes de verificar</td>
										<td>{dataClientes.verified_pending}</td>
									</tr>

									<tr>
										<th>Total clientes cerrados</th>
										<th>{dataClientes.closed}</th>
									</tr>
								</table>
							</div>
							<div className='pieChartStyle'>
								<AnyChart
									id="pieChartClientVerif"
									width={350}
									height={250}
									instance={pieChart2}
									title="Número de clientes verificados"
								/>
							</div>
						</div>
					</div>
				</div>
				}
				<div className='contReport'>
					<div>
						<h3>Vehículos</h3>
						<div className='containerTable tableReporting'>
							<div>
								<table className='tableClient'>
									<tr>
										<td>Cerrado</td>
										<td>{dataVehiculos.closed}</td>
									</tr>
									<tr>
										<td>Pendientes de cerrar</td>
										<td>{dataVehiculos.open}</td>
									</tr>
									<tr>
										<th>Total vehículos</th>
										<th>{dataVehiculos.closed + dataVehiculos.open}</th>
									</tr>
								</table>
							</div>
							<div className='pieChartStyle'>
								<AnyChart
									id="pieChartCarClose"
									width={350}
									height={250}
									instance={pieChart3}
									title="Número de vehículos cerrados"
								/>
							</div>
							<div>
								<table className='tableClient'>
									<tr>
										<td>Verificados</td>
										<td >{dataVehiculos.verified}</td>
									</tr>
									<tr>
										<td>Pendientes de verificar</td>
										<td>{dataVehiculos.verified_pending}</td>
									</tr>

									<tr>
										<th>Total vehículos cerrados</th>
										<th>{dataVehiculos.closed}</th>
									</tr>
								</table>
							</div>
							<div className='pieChartStyle'>
								<AnyChart
									id="pieChartCarVerif"
									width={350}
									height={250}
									instance={pieChart4}
									title="Número de vehículos verificados"
								/>
							</div>
						</div>
					</div>
				</div>
				<div className='contReport'>
					<div>
						<h3>Nº Total de vehículos por marca</h3>
						<Grid container spacing={0.5}>
							<Grid item xs={4} alignItems="center">
								<table className='tableClientAbby'>
									<tr>
										<th>Marca</th>
										<th>Vehículos</th>
									</tr>
									{dataVehiculosPorMarca.map((data) => (
										<tr>
											<td>{data.model__brand__name}</td>
											<td>{data.id__count}</td>
										</tr>
									))}
								</table>
							</Grid>
							
							<Grid item xs={8} alignItems="center">
								{id_marca != '' &&
									<div className='columnChart'>
										<div className='tit'>
											<label>Cerrados</label>
											<select id="closed" name ="closed" onChange={handleChange} value={values.closed}>
												<option key={0} value= "">Todos</option>
												<option key={1} value= {1}>Cerrado</option>
												<option key={2} value= {0}>No cerrado</option>
											</select>
											<label>Verificados</label>
											<select id="verified" name ="verified" onChange={handleChange} value={values.verified}>
												<option key={0} value= "">Todos</option>
												<option key={1} value= {1}>Verificados</option>
												<option key={2} value= {0}>No verificados</option>
											</select>
										</div>

										<div>
											<AnyChart
												id="columnChart3"
												instance={chart}
												height={400}
												title="Total de vehículos por marca y mes/años" 
											/> 
										</div>
										<div className='legends'>
											<div>
												<div className='Infraccion'></div>
												<span>Periodo de infracción</span>
											</div>
											<div>
												<div className='NoInfraccion'></div>
												<span>Periodo sin infracción</span>
											</div>
											
										</div>
									</div>
								}
								{id_marca == '' &&
									<div className='columnChart'>
										<div className='tit'>
											<label>Seleccione una marca para visualizar</label>
										</div>
									</div>
								}
							</Grid>
						</Grid>
					</div>
				</div>
			</div>
		</Tabs.Tab>
		<Tabs.Tab id="abby" title="Informes Abby">
			<div style={{ padding: 10 }}>
				<h2 className='titReport'>Reporting Abby</h2>
				<div className='tabsReport'>
					<div>
						<label>Organización</label>
						<SelectEntity/>	
					</div>
					<div>
						<label>Empresa</label>
						<SelectCompany/>
					</div>
					<div>
						<label>Año de compra</label>
						<select id="year" defaultValue={''} name ="year" onChange={handleChange} value={values.year}>
							<option value=''>TODOS</option>
							<option value="2000">2000</option>
							<option value="2001">2001</option>
							<option value='2002'>2002</option>
							<option value="2003">2003</option>
							<option value="2004">2004</option>
							<option value='2005'>2005</option>
							<option value='2006'>2006</option>
							<option value="2007">2007</option>
							<option value="2008">2008</option>
							<option value="2009">2009</option>
							<option value='2010'>2010</option>
							<option value="2011">2011</option>
							<option value="2012">2012</option>
							<option value='2013'>2013</option>
							<option value="2014">2014</option>
							<option value='2015'>2015</option>
							<option value='2016'>2016</option>
							<option value="2017">2017</option>
							<option value="2018">2018</option>
							<option value="2019">2019</option>
							<option value='2020'>2020</option>
							<option value="2021">2021</option>
							<option value="2022">2022</option>
						</select>
					</div>
					<div>
						<label>Marca</label>
						<Grid item><div ><SelectMarca/></div></Grid>
					</div>
				</div>
				
				<div className='contReport'>
					{id_company == '' &&
						<div>
							<h3>Clientes</h3>
							<Grid container spacing={0.5}>
								<Grid item xs={4} alignItems="center">
									<table className='tableClientAbby'>
										<tr>
											<td>Cerrado</td>
											<td>{dataClientes.closed}</td>
										</tr>
										<tr>
											<td>Pendientes de cerrar</td>
											<td>{dataClientes.open}</td>
										</tr>

										<tr>
											<th>Total clientes</th>
											<th>{dataClientes.closed + dataClientes.open}</th>
										</tr>
									</table>
								</Grid>
								
								<Grid item xs={4} alignItems="center">
									<table className='tableClientAbby'>
										<tr>
											<td>Verificados</td>
											<td >{dataClientes.verified}</td>
										</tr>
										<tr>
											<td>Pendientes de verificar</td>
											<td>{dataClientes.verified_pending}</td>
										</tr>

										<tr>
											<th>Total clientes cerrados</th>
											<th>{dataClientes.closed}</th>
										</tr>
									</table>
								</Grid>
								
								<Grid item xs={4} alignItems="center">
									<table className='tableClientAbby'>
										<tr>
											<td>Procesados</td>
											<td >{dataProcessedClientes.processed}</td>
										</tr>
										<tr>
											<td>Pendientes de procesar</td>
											<td >{dataProcessedClientes.pending}</td>
										</tr>

										<tr>
											<th>Total clientes cerrados</th>
											<td >{dataProcessedClientes.processed + dataProcessedClientes.pending}</td>
										</tr>
									</table>
								</Grid>
							</Grid>
							<Grid container spacing={0.5}>
								<Grid item xs={4} alignItems="center">
									<AnyChart
										id="pieChartClientClose"
										width={350}
										height={250}
										instance={pieChart1}
										title="Número de clientes cerrados"
									/>
								</Grid>

								<Grid item xs={4} alignItems="center">
									<AnyChart
										id="pieChartClientVerif"
										width={350}
										height={250}
										instance={pieChart2}
										title="Número de clientes verificados"
									/>
								</Grid>

								<Grid item xs={4} alignItems="center">
									<AnyChart
										id="pieChartClientProccesados"
										width={350}
										height={250}
										instance={pieChartClientesProcesados}
										title="Número de clientes verificados"
									/>
								</Grid>
							</Grid>
						</div>
					}
				</div>
				<div className='contReport'>
					<div>
						<h3>Vehículos</h3>
						
						<Grid container spacing={0.5}>
							<Grid item xs={4} alignItems="center">
								<table className='tableClientAbby'>
									<tr>
										<td>Cerrado</td>
										<td>{dataVehiculos.closed}</td>
									</tr>
									<tr>
										<td>Pendientes de cerrar</td>
										<td>{dataVehiculos.open}</td>
									</tr>
									<tr>
										<th>Total vehículos</th>
										<th>{dataVehiculos.closed + dataVehiculos.open}</th>
									</tr>
									
								</table>
							</Grid>
							
							<Grid item xs={4} alignItems="center">
								<table className='tableClientAbby'>
									<tr>
										<td>Verificados</td>
										<td >{dataVehiculos.verified}</td>
									</tr>
									<tr>
										<td>Pendientes de verificar</td>
										<td>{dataVehiculos.verified_pending}</td>
									</tr>

									<tr>
										<th>Total vehículos cerrados</th>
										<th>{dataVehiculos.closed}</th>
									</tr>
								</table>
							</Grid>
							
							<Grid item xs={4} alignItems="center">
								<table className='tableClientAbby'>
									<tr>
										<td>Procesados</td>
										<td >{dataProcessedVehiculos.processed}</td>
									</tr>
									<tr>
										<td>Pendientes de procesar</td>
										<td>{dataProcessedVehiculos.pending}</td>
									</tr>

									<tr>
										<th>Total vehículos cerrados</th>
										<th>{dataProcessedVehiculos.processed + dataProcessedVehiculos.pending}</th>
									</tr>
								</table>
							</Grid>
						</Grid>


						<Grid container spacing={0.5}>
							<Grid item xs={4} alignItems="center">
								<AnyChart
									id="pieChartCarClose"
									width={350}
									height={250}
									instance={pieChart3}
									title="Número de vehículos cerrados"
								/>
							</Grid>
							<Grid item xs={4} alignItems="center">
								<AnyChart
									id="pieChartCarVerif"
									width={350}
									height={250}
									instance={pieChart4}
									title="Número de vehículos verificados"
								/>
							</Grid>
							<Grid item xs={4} alignItems="center">
								<AnyChart
									id="pieChartCarProcess"
									width={350}
									height={250}
									instance={pieChartCochesProcesados}
									title="Número de vehículos procesados"
								/>
							</Grid>
						</Grid>
					</div>
				</div>
				<div className='contReport'>
					<div>
						<h3>Nº Total de vehículos por marca</h3>
						
						<Grid container spacing={0.5}>
							<Grid item xs={3} alignItems="center">
								<table className='tableClientAbby'>
									<tr>
										<th colspan="2" scope="colgroup">Declarados</th>
									</tr>
									<tr>
										<th>Marca</th>
										<th>Vehículos</th>
									</tr>
									{dataVehiculosPorMarca.map((data) => (
										<tr>
											<td>{data.model__brand__name}</td>
											<td>{data.id__count}</td>
										</tr>
									))}
								</table>
							</Grid>
						
							<Grid item xs={3} alignItems="center">
								<table className='tableClientAbby'>
									<tr>
										<th colspan="2" scope="colgroup">Procesados</th>
									</tr>
									<tr>
										<th>Marca</th>
										<th>Vehículos</th>
									</tr>

									{dataProcessedVehiculosPorMarca.map((data) => (
										<tr>
											<td>{data.model}</td>
											<td>{data.count}</td>
										</tr>
									))}

								</table>
							</Grid>
							
							<Grid item xs={6} alignItems="center">
								{id_marca != '' &&
									<Fragment>
									<AnyChart
										id="columnChart3"
										instance={chartProcessed}
										height={400}
										title="Total de vehículos por marca y mes/años" 
									/>
									<div className='legends'>
										<div>
											<div className='Infraccion'></div>
											<span>Periodo de infracción</span>
										</div>
										<div>
											<div className='NoInfraccion'></div>
											<span>Periodo sin infracción</span>
										</div>
									</div>
									</Fragment>
								}

								{id_marca == '' &&
									<div className='columnChart'>
										<div className='tit'>
											<label>Seleccione una marca para visualizar</label>
										</div>
									</div>
								}
							</Grid>
						</Grid>
					</div>
				</div>	
			</div> 
			
			
		</Tabs.Tab>
	</Tabs>
);
};


export default Reporting
import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {clienteAxiosNoJWT, clienteAxios} from '../config/axios'
import { UserContext } from './UserContext';
export default function useAuth() {
    const navigate = useNavigate();
    const { setUser } = useContext(UserContext);
    const [error, setError] = useState(null);

    const setUserContext = async () => {
        return await clienteAxios.get('auth/userdata')
            .then(res => {
                setUser(res.data.result);
                localStorage.setItem("userDataArray", JSON.stringify(res.data.result));
                res.data.result.terms_accepted ? navigate('/') : navigate('/terms');
                })
            .catch((err) => {
                console.log(err)
                setError(err.response.data);
            })
    }

    //login user 
    const loginUser = async (data) => {
        const { username, password } = data;
        return clienteAxiosNoJWT.post('auth/login', {
            email: username,
            password: password
        }).then(async (result) => {
            localStorage.setItem("token_access", result.data.access);
            localStorage.setItem("token_refresh", result.data.refresh);
            localStorage.setItem("state_auth", JSON.stringify({isAuthenticated: true}))
            await setUserContext();
        }).catch((err) => {
            console.log(console.log(err.response.status))
            if (err.response.status == 307) {
                navigate("/update-password", { replace: true,  state:{email: username} });
            }
            else {
                setError(err);
            }
            console.log('error de login')
        })
    };

    return { loginUser, error}
}
import React, { useState, useMemo, Fragment, useEffect } from 'react';
import {useParams} from "react-router-dom";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FaPlusCircle, FaEdit } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import Divider from '@mui/material/Divider';
import CarModal from '../modals/CarModal';
import useFetchApi from '../../hooks/useFetchApi';
import {clienteAxios} from '../../config/axios'
import ClientModal from '../modals/ClientModal'
import { useNavigate } from 'react-router-dom';
import Box from "@material-ui/core/Box";
import Button from '@mui/material/Button';
import EnhancedTable from "../../UI/BaseTable/EnhancedTable";
import "./customerVehicle.css"

const CustomerVehicle = (props) => {

    const headCells = [
        {
            id: 'brand',
            numeric: false,
            disablePadding: false,
            label: 'Marca',
        },
        {
            id: 'model',
            numeric: false,
            disablePadding: false,
            label: 'Modelo',
        },
        {
          id: 'plate',
          numeric: false,
          disablePadding: false,
          label: 'Vehículo',
        },
        {
          id: 'chassis',
          numeric: false,
          disablePadding: false,
          label: 'Bastidor',
        },
        {
          id: 'actions',
          numeric: false,
          disablePadding: false,
          label: 'Acciones',
        }
    ];

    const navigate = useNavigate();
    const {id} = useParams();

    const [{ elements, totalElements, isLoading, isError }, doFetch] = useFetchApi();
    const [idCarEdit, setIdCarEdit] = useState(null);
    const [cars, setCars] = useState([]);
    const [showModalCoches, setShowModalCoche] = useState(false);
    const [showModalCliente, setShowModalCliente] = useState(false);
    const [modCarList, setModCarList] = useState(false);
    const [editedCompany, setEditedCompany] = useState(false);

    
    useMemo(() => {
        doFetch({url: 'compute/getCompany/'+ id})
    }, [editedCompany])

    useEffect(() => {
        if (!isLoading && elements) setCars(elements.cars)
    }, [isLoading])

    useEffect(() => {
        if (modCarList) getCars()
    }, [modCarList])

    const handleShowModalCoche = (id) => {

        setShowModalCoche(true);
        if (id) {
            setIdCarEdit(id)
        } else {
            setIdCarEdit(null)
        }
    }

    const getCars = async () => {
        let urlLocalizacion = `compute/getCompanyCars/${id}`;
        try {
            const cars = await clienteAxios.get(urlLocalizacion);
            setCars(cars.data);
			setModCarList(false)
        } catch (error) {
            console.log(error);
            setCars([]);
        }
    }

    const handleDeleteCar = (id) => {

        if (window.confirm('¿Desea borrar el vehículo junto con su documentación?')) {

            let config = {
                method: 'delete',
                url: 'compute/deleteCar/' + id
            }
            
            clienteAxios(config)
                .then(function (response) {
                    setCars(cars.filter(item => item.id !== id))
                })
                .catch(function (error) {
                    console.log(error);
                    alert('Error al borrar el vehículo')
                });
        }
    }


    const handleCloseModalCoche = () => {setShowModalCoche(false);}
    const handleCloseModalCliente = () => {setShowModalCliente(false);}
	const handleShowModalCliente = () => {setShowModalCliente(true);}

    const closeCompany = async () => {

        var config = {
            method: 'get',
            url: 'compute/closeCompany/' + elements.id,
        };
          
        clienteAxios(config)
        .then((result) => {
            alert(result.data.msg);
            navigate('/customerManagement')
        })
        .catch((error) => {
            alert('Error');
            console.log(error);
        })
    };
    
       
	return (
        <Fragment>
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
                aligncontent="flex-start"
                spacing={4}
                className= "containerAddDocument"
            >
                <Grid item xs={12} className="titAddDocument">
                <Typography>
                    <h3>Cliente </h3>
                </Typography>
                { ! elements.is_closed &&
                    <div className='divAddDocument'>
                        <div className='addDocument' type='button' onClick={() =>handleShowModalCliente()}>
                        <div> <FaPlusCircle/> </div> 
                        <div> <p>Editar empresa</p> </div>
                
                        </div>
                        <div className='addDocument' type='button' onClick={() =>handleShowModalCoche()}>
                            <div> <FaPlusCircle/> </div> 
                            <div> <p>Añadir vehículo</p> </div>
                        </div>
                    </div>
                }
                </Grid>
			
			
                <Divider />
                {
                    showModalCoches && 
                    <Fragment>
                        <CarModal
                            isOpen={showModalCoches}
                            onRequestClose={handleCloseModalCoche}
                            id = {idCarEdit}
                            onSave = {setModCarList}
                        />
                    </Fragment>
                }

                {
                    showModalCliente && 
                    <Fragment>
                        <ClientModal
                            isOpen={showModalCliente}
                            onRequestClose={handleCloseModalCliente}
                            companyData = {elements}
                            onSave = {setEditedCompany}
                        />
                    </Fragment>
			    }

            </Grid>
            <div className='containerDataCustomer'>
                <Fragment>
                    <div>
                        <h4>{"Organización"}</h4>
                        <p>{elements.entity}</p>
                    </div>
                    <div>
                        <h4>CIF/NIF</h4>
                        <p>{elements.id_number}</p>
                    </div>
                    <div>
                        <h4>Nombre</h4>
                        <p>{elements.name}</p>
                    </div>
                    <div>
                        <h4>Tipo</h4>
                        <p>{elements.type}</p>
                    </div>
                    <div>
                        <h4>Cerrado</h4>
                        <p>{elements.is_closed ? "Sí" : "No"}</p>
                    </div>
                </Fragment>
                       
            </div>
                { isError && <div className='error'>Error al obtener datos de la base de datos</div>}

				{
				isLoading &&
					<div className="text-center">
						<div className="spinner-border" role="status">
							<span className="sr-only">Loading...</span>
						</div>
					</div>
				}
                
            <div className='table iconAction'>
                {
                    !isError && !isLoading && cars &&
                        <EnhancedTable
                            rows = {cars}
                            headCells = {headCells}
                            title = {"Listado de vehículos"}
                            actionFunction = { !elements.is_closed ? [handleShowModalCoche, handleDeleteCar]: ''}
                            actionName = {[<FaEdit/>, <AiFillDelete/>]}
                        />
                }                  
            </div>

            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="15vh"
            >
                <Button variant="contained" disabled={ elements.is_closed } color="success" onClick={() => closeCompany()}>Cerrar empresa</Button>
            </Box>
        </Fragment>
    );

};


export default CustomerVehicle
import React, { useEffect, useState, useMemo, Fragment } from 'react';
import EnhancedTable from "../../UI/BaseTable/EnhancedTable";
import useFetchApi from '../../hooks/useFetchApi';
import useForm from '../../hooks/useForm';
import { FaPlusCircle } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import { useNavigate } from 'react-router-dom';
import {clienteAxios} from '../../config/axios';
import useSelector from '../../hooks/useSelector';
import Grid from '@mui/material/Grid';
import "./documents.css"


const Documents = (props) => {

	const navigate = useNavigate();
	const [{ elements, totalElements, isLoading, isError, setElements }, doFetch] = useFetchApi();
	const [listaEntity, setListaEntity] = useState([])
	const [listaDocumentos, setListaDocumentos] = useState([]);
    const [id_entity, SelectEntity, setIdEntity] = useSelector('', '', listaEntity);

	useEffect(() => {

        const getEntities = async () => {
            let urlLocalizacion1 = 'compute/getEntities';
            try {
                const entity = await clienteAxios.get(urlLocalizacion1);
                setListaEntity(entity.data);
                if (entity.data.length == 1) {
                    setIdEntity(entity.data[0].id)
                }

            } catch (error) {
                console.log(error);
                setListaEntity([]);
            }
        }

		const getDocumentTypes = async () => {
			let urlLocalizacion1 = 'compute/documentSpecies';
			try {
				const docs = await clienteAxios.get(urlLocalizacion1);
				setListaDocumentos(docs.data[0].result);
				
			} catch (error) {
				console.log(error);
				setListaDocumentos([]);
			}
		}

        getEntities();
		getDocumentTypes();

    }, [])

	useEffect(() => {

		setValues({
			...values,
			['entity']: id_entity
		}); 

    }, [id_entity])
	
	const { values, handleChange, setValues } = useForm({
		initialValues: {
			name: '',
			car: '',
			company: '',
			entity: '',
			type: '',
			upload_by: '',
			status: ''
		}
	});

	useMemo(() => {
		doFetch({url: 'compute/getFiles', params: values})
	}, [])

	const handleSearch = async (e) => {
        e.preventDefault();
        doFetch({url: 'compute/getFiles', params: values})
    }

	const handleShow = () => {navigate('/add-documents');}

	const handleDeleteFile = (id) => {
        let config = {
            method: 'delete',
            url: 'compute/deleteFile/' + id
        }

        clienteAxios(config)
            .then(function (response) {
				setElements(elements.filter(item => item.id !== id))
            })
            .catch(function (error) {
                console.log(error);
                alert('Error al borrar el fichero')
            });
          
    }

    const headers = [
      { 
        id: 'id', 
        label: 'ID Archivo', 
        minWidth: 60 
      },
      { 
        id: 'file', 
        label: 'Nombre del archivo', 
        minWidth: 100
      },
      {
        id: 'car',
        label: 'Matrícula',
        minWidth: 60,
        align: 'right',
      },
      {
        id: 'company',
        label: 'Empresa',
        minWidth: 90,
        align: 'right',
        
      },
      {
        id: 'entity',
        label: 'Organización',
        minWidth: 105,
        align: 'right',
        
      },
      {
        id: 'document_species',
        label: 'Tipo de documento',
        minWidth: 150,
        align: 'right',
        
      },
      {
        id: 'upload_by',
        label: 'Subido por',
        minWidth: 100,
        align: 'right',
        
      },
      {
        id: 'status',
        label: 'Estado',
        minWidth: 150,
        align: 'right',
        
      },
      {
        id: 'ts_upload',
        label: 'Fecha de carga',
        minWidth: 90,
        align: 'right'
        
      },
	  {
		id: 'actions',
		numeric: false,
		disablePadding: false,
		label: 'Acciones',
	  }
    ];

	return (
		<Fragment>
			<div className='containerTable'>
          		<div className='headerSearch'>
					<div>
						<h3>Buscador documentos:</h3>
					</div>
            		<div className='addDocument' type='button' onClick={() =>handleShow()}> 
						<div>
						<FaPlusCircle/>
						</div> 
						<div>
							<p>Añadir Documento</p>
						</div>
              		</div>
          		</div>
			  
				<form onSubmit={handleSearch}>
					<div className='search'>
						<div>
							<label>Nombre del archivo</label>
							<input 
								type="text"
								name="name"
								className="form-control"
								onChange={handleChange}
								value={values.name}
							/>
						</div>

						<div>
							<label>Matrícula</label>
							<input 
								type="text"
								name="car"
								className="form-control"
								onChange={handleChange}
								value={values.car}
							/>
						</div>
						<div>
							<label>Empresa</label>
							<input 
								type="text"
								name="company"
								className="form-control"
								onChange={handleChange}
								value={values.company}
							/>
						</div>

						<div>
							<label>Organización</label>
							<Grid item><div ><SelectEntity/></div></Grid>
						</div>

						<div>
							<label>Tipo de documento</label>
							<select id="type" name ="type" onChange={handleChange} value={values.type}>
								<option key={0} value= "">Todos</option>
								{listaDocumentos.map(opcion => (
									<option key={opcion.id} value={opcion.id}>{opcion.value}</option>
								))}
							</select>
						</div>
						<div>
							<label>Subido por</label>
							<input 
								type="text"
								name="upload_by"
								className="form-control"
								placeholder=""
								onChange={handleChange}
								value={values.upload_by}
							/>
						</div>
						<div>
							<label>Estado</label>
							<select id="status" name ="status" onChange={handleChange} value={values.status}>
								<option value="">Todos</option>
								<option value="Subido a la plataforma">Subido a la plataforma</option>
								<option value="Preparado para procesar">Preparado para procesar</option>
								<option value="Enviado a procesar">Enviado a procesar</option>
								<option value="Procesado inicial">Procesado inicial</option>
								<option value="Exportado">Exportado</option>
							</select>
						</div>
					</div>
					<div className='searchBtn'>
						<button className='btn' type='submit'>Buscar</button>
					</div>
				</form>
        	</div>
			{isError && <div className='error'>Error al obtener datos de la base de datos</div>}

			{
			isLoading &&
				<div className="text-center">
					<div className="spinner-border" role="status">
						<span className="sr-only">Loading...</span>
					</div>
				</div>
			}
			
			{
				!isError && !isLoading && elements.length > 0 &&
					<div className='table iconAction'>
						<EnhancedTable
							rows = {elements}
							headCells = {headers}
							title = {"Listado de Documentos"}
							actionFunction = { [handleDeleteFile]}
							actionName = {[<AiFillDelete/>]}
							disabledField = {'is_closed'}
						/>
					</div>
			}
		</Fragment>
	);
};


export default Documents
import React, { useEffect, useState, useMemo, Fragment } from 'react';
import PageTable from "../../UI/BaseTable/PageTable";
import useFetchApi from '../../hooks/useFetchApi';
import useForm from '../../hooks/useForm';
import { FaPlusCircle } from "react-icons/fa";

import ClientModal from '../modals/ClientModal'
import {clienteAxios} from '../../config/axios';
import useSelector from '../../hooks/useSelector';
import Grid from '@mui/material/Grid';

import "./customerManagement.css"

const CustomerManagement = (props) => {
 
	const [{ elements, totalElements, isLoading, isError }, doFetch] = useFetchApi();

	const [listaEntity, setListaEntity] = useState([])
	const [id_entity, SelectEntity, setIdEntity] = useSelector('', '', listaEntity);
	const [show, setShow] = useState(false);
	const [addedCompany, setAddedCompany] = useState(false);

	const { values, handleChange, setValues } = useForm({
		initialValues: {
		entity: '',
		id: '',
		id_number: '',
		is_closed: '',
		is_verified: '',
		name: '',
		super: '',	
		type: ''
		}
	});

	useEffect(() => {

		const getEntities = async () => {
			let urlLocalizacion1 = 'compute/getEntities';
			try {
				const entity = await clienteAxios.get(urlLocalizacion1);
				setListaEntity(entity.data);
				if (entity.data.length == 1) {
					setIdEntity(entity.data[0].id)
				}

			} catch (error) {
				console.log(error);
				setListaEntity([]);
			}
		}

		getEntities()

	}, [])

	useEffect(() => {

		setValues({...values, ['entity']: id_entity}); 

	}, [id_entity])

	useMemo(() => {
		doFetch({url: 'compute/getCompanies', params: values})
	}, [])

	const handleSearch = async (e) => {
		e.preventDefault();
		doFetch({url: 'compute/getCompanies', params: values})
	}

	const handleClose = () => {setShow(false);}
	const handleShow = () => {setShow(true);}

	// const closeCompany = async () => {

    //     var config = {
    //         method: 'get',
    //         url: 'compute/closeCompany/' + elements.id,
    //     };
          
    //     clienteAxios(config)
    //     .then((result) => {
    //         alert('Cliente cerrado');
    //         navigate('/customerManagement')
    //     })
    //     .catch((error) => {
    //         alert('Error');
    //         console.log(error);
    //     })
    // };


	const headers = [
		{ 
			id: 'name', 
			label: 'Nombre', 
			minWidth: 100,
			link: 'customerVehicle'
		},
		{ 
			id: 'entity', 
			label: 'Organización', 
			minWidth: 60
		},
		{
			id: 'id_number',
			label: 'CIF/NIF',
			minWidth: 60,
			align: 'right',
		},
		{
			id: 'type',
			label: 'Tipo',
			minWidth: 150,
			align: 'right',
		},
		{
			id: 'is_closed',
			label: 'Cerrado',
			minWidth: 90,
			align: 'right',
			visible: show,
		// format: (elements[0].is_closed) , 
		},
		{ 
			id: 'num_cars',
			label: 'Número de vehículos',
			minWidth: 90,
			align: 'right',
			visible: show,
			// format: (elements[0].is_closed) , 
		},
		// {
		// 	id: 'actions',
		// 	minWidth: 90,
		// 	align: 'right',
		// 	label: 'Acciones',
		// }
  	];

	return (
		<Fragment>
			
			<div className='containerTable'>
				<div className='headerSearch'>
					<div>
						<h3>Clientes:</h3>
          			</div>
				<div className='addDocument' type='button' onClick={() =>handleShow()}>
					<div>
						<FaPlusCircle/>
					</div> 
					<div>
						<p>Añadir cliente</p>
					</div>
				</div>
        	</div>
			  
			<form onSubmit={handleSearch}> 
      
				<div className='search'>
					<div>
						<label>Organización</label>
						<Grid item><div ><SelectEntity/></div></Grid>
                    </div>
					<div>
						<label>NIF cliente</label>
						<input 
							type="text"
							name="id_number"
							className="form-control"
							onChange={handleChange}
							value={values.id_number}
						/>
					</div>
					<div>
						<label>Nombre</label>
						<input 
							type="text"
							name="name"
							className="form-control"
							onChange={handleChange}
							value={values.name}
						/>
					</div>
          				{/*<div>
						<label>Tipo</label>
						<input 
							type="text"
							name="type"
							className="form-control"
							onChange={handleChange}
							value={values.type}
						/>
						</div>*/}
					<div>
						<label>Cerrado</label>
						<select id="is_closed" name ="is_closed" onChange={handleChange} value={values.is_closed}>
							<option value="">Todos</option>
							<option value="True">Sí</option>
							<option value="False">No</option>
						</select>
					</div>
				</div>
				<div className='searchBtn'>
          <button className='btn' type='submit'>Buscar</button>
        </div>
			</form>
      
        </div>
         
				{ isError && <div className='error'>Error al obtener datos de la base de datos</div>}

				{
				isLoading &&
					<div className="text-center">
						<div className="spinner-border" role="status">
							<span className="sr-only">Loading...</span>
						</div>
					</div>
				}
				
				{
					!isError && !isLoading && elements.length > 0 &&
						<div className='table'>
            	 <PageTable 
                headers = { headers } 
                elements = { elements }
              />
						</div>
				}

				{
					show && 
            <Fragment>
              <ClientModal
                isOpen={show}
                onRequestClose={handleClose}
				onSave = {setAddedCompany}
              />
            </Fragment>
				}
			</Fragment>
		);
	};


export default CustomerManagement
import React, { useEffect, useState, Fragment } from 'react';
import FileCard from './FileCard';
import Grid from '@mui/material/Grid';




const ClientFiles = ({uploadedList, car, listaDocumentos, company}) => {

    const [hasFile, setHasFile] = useState(new Map())
        
    useEffect(() => {
        let obj = new Map();

        for (let i = 0; i < uploadedList.length; i ++) {
            let type = uploadedList[i].document_species_id
            let file = {id: uploadedList[i].id, name : uploadedList[i].file + " (Subido el " + uploadedList[i].ts_upload + ')'}

            if (!obj.has(type)) obj.set(type, [])
            obj.get(type).push(file)
        }

        setHasFile(obj);

	}, [uploadedList]);

    return (
		<Fragment >
            <Grid
			container
			direction="row"
			justify="flex-start"
			alignItems="flex-start"
			aligncontent="flex-start"
			spacing={1}
            className='optionsAddDocumentSelect'
		  >

            {car &&
                listaDocumentos.map(document => (
                    <div className='filesCard'>
                        <FileCard 
                            car = {car}
                            document_type_name = {document.value}
                            document_type_id = {document.id}
                            file = {hasFile.has(document.id) ? hasFile.get(document.id) : []}
                            
                        />
                    </div>
                ))
            }

            {company &&
                listaDocumentos.map(document => (
                    <Grid item xs={4}>
                        <FileCard 
                            company = {company}
                            document_type_name = {document.value}
                            document_type_id = {document.id}
                            file = {hasFile.has(document.id) ? hasFile.get(document.id) : []}
                        />
                    </Grid>
                ))
            }
            </Grid>
        </Fragment>
	  );
	};


export default ClientFiles
import React, {Fragment, useState, useEffect} from 'react';
import FormInput from '../FormInput';
import useForm from '../../hooks/useForm';
import {clienteAxiosNoJWT} from '../../config/axios'
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import {useLocation} from 'react-router-dom';

import './login.css'

const ResetPassword = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const email = location.state && location.state.email ? location.state.email : ''

    useEffect(() => {

		if (!location.state ||location.state.email.length == 0) {
            navigate('/login')
        }

	}, []);

    
    const [errorForm, setErrorForm] = useState(false)
    const { values, handleChange } = useForm({
        initialValues: {
            current_password: '',
            new_password: '',
            password_confirm: ''
        }
    });

    const handleLogin = async (e) => {
        e.preventDefault();

        if (values.current_password.length > 0 && values.new_password .length > 0 && values.new_password == values.password_confirm) {
            return clienteAxiosNoJWT.post('auth/renewpass', {
                email: email,
                securitypassword: values.current_password,
                password: values.password_confirm
            }).then(async (result) => {
                navigate('/login');
            }).catch((err) => {
                console.log(err)
            })
        } else setErrorForm(true)
    }

    return (
        <Fragment>
            <form onSubmit={handleLogin}>
            <section className="Login">
                <h3>Elige una nueva contraseña</h3>
                <h5>{email}</h5>
                <div>
                    <FormInput
                            type="password"
                            name="current_password"
                            value={values.current_password}
                            placeholder = 'Nueva contraseña'
                            handleChange={handleChange}
                    />
                    <FormInput
                            type="password"
                            name="new_password"
                            value={values.new_password}
                            placeholder = 'Nueva contraseña'
                            handleChange={handleChange}  
                    />
                    <FormInput
                            type="password"
                            name="password_confirm"
                            value={values.password_confirm}
                            placeholder = 'Confirme contraseña'
                            handleChange={handleChange}  
                    />

                    <Grid item xs={12}>
                        <Typography
                            display="block"
                            align="center"
                        >
                        <small>Con el fin de mejorar su seguridad es necesario cambiar la contraseña
                        de forma periódica. Si tiene algún problema con este trámite
                        por favor póngase en contacto con el administrador para recibir ayuda.</small>
                        </Typography>
                    </Grid>
                </div>
                <div>
                    <span> 
                        <div>
                            <button className="btn" type='submit'>enviar</button>
                        </div>
                    </span>
                </div>
                {
                    errorForm &&
                    <div style={{marginTop: 10}}>
                        <Alert 
                            severity="warning"
                        >
                        Los campos no pueden estar vacíos y deben coincidir
                        </Alert>
                    </div>
                }
            </section>
            </form>
        </Fragment>
    );
}

export default ResetPassword
import React from 'react';
import { Navigate } from 'react-router-dom';

export default function PrivateRoute({children, only_admin}) {

    const auth = JSON.parse(localStorage.getItem("state_auth"))
    const user = JSON.parse(localStorage.getItem("userDataArray"))

    if (only_admin && user) {
        
        return  user.is_admin ? children : <Navigate to='/' />
    }

    return auth && auth.isAuthenticated ? children : <Navigate to='/login' />
}
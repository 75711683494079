import React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import {clienteAxios} from '../../config/axios'
import Box from "@material-ui/core/Box";
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';


const Terms = () => {
    
    const navigate = useNavigate();
    const acceptTerms = async () => {

        var config = {
            method: 'post',
            url: 'compute/acceptTerms',
        };
        
        clienteAxios(config)
        .then((result) => {
            navigate('/')
        })
        .catch((error) => {
            alert('Error');
            console.log(error);
        })
    };

    return (
      <Grid container direction="row" justify="center" alignItems="center" style ={{justifyContent: "center"}}>
        <Paper style={{ width: "90%", marginTop: 30, padding: 15 }}>
          <p>
            <Typography variant="h3">
              Términos y condiciones de uso de la Plataforma de captura de
              documentos
            </Typography>
            <br />
            <br />
            <Typography variant="h4">
              Introducción y datos de la compañía
            </Typography>
            <br />
            <Typography variant="textPrimary">
              Las presentes disposiciones regulan el uso de la plataforma de
              captura de documentos (en adelante, “la Plataforma” o la
              “Herramienta”) para la gestión integral del proyecto, como parte
              del servicio prestado por KPMG Asesores, S.L. (en adelante,
              “KPMG”) al Cliente contratante de los mismos (en adelante, el
              “Cliente”).
              <br />
              KPMG Asesores, S.L. es una sociedad española con domicilio social
              en Paseo de la Castellana 259-C, 28046, Madrid, inscrita en el
              Registro Mercantil de Madrid, Tomo 14.972, Libro 0, Folio 53,
              Sección 8, Hoja M-249.480, inscripción 1ª y C.I.F: B-82498650.
              <br />
              Como parte de la política global de dar un valor añadido a los
              servicios que presta a sus Clientes, KPMG ofrece a éstos una
              herramienta de trabajo para la captura, gestión y procesamiento de
              documentación denominada “Plataforma de captura de documentos” que
              permite al Cliente, así como a sus empleados autorizados y, en su
              caso a terceros que estén involucrados en el proyecto y que sean
              expresamente designados autorizados para ello con una relación
              contractual con el Cliente en el marco del proyecto, (en adelante
              “Usuarios Autorizados” y/o “Usuario/s”). la gestión de la
              documentación a procesar dentro de un entorno online protegido.
              <br />
              La Plataforma es una herramienta que se presenta en modo software
              como servicio (SaaS) basada en un soporte web protegido que
              permite la colaboración entre equipos en un entorno virtual y
              almacena la información y documentación compartida a través de la
              misma. La Plataforma utiliza un desarrollo de software in Company
              basado en una aplicación Python con el framework Django.
              <br />
              El Cliente y los Usuarios aceptan que el mero uso de la Plataforma
              y/o de aquellas comunicaciones o correspondencia derivadas de su
              utilización, no genera por sí solo relación profesional alguna
              entre el Cliente/Usuario y KPMG, debiéndose estar, a los indicados
              fines, al contenido de la carta de encargo que las partes
              formalicen. De igual forma, tampoco se verán afectadas las
              relaciones u obligaciones profesionales entre ambas partes por el
              uso, comunicación o correspondencia relacionadas con la
              Plataforma.
              <br />
              La Plataforma en tanto es una herramienta colaborativa y de
              gestión, permite:
              <List>
                <ListItem>
                  <ListItemText primary="Subir documentos a los Usuarios para que sean procesados posteriormente por KPMG." />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Gestionar los accesos y permisos según la tipología de Usuarios que se conecte a la plataforma." />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Facilitar estadísticas básicas de la documentación subida a la Plataforma por los diferentes Usuarios." />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Disponer de un log de actividades llevadas a cabo en la Plataforma por cada uno de los Usuarios." />
                </ListItem>
              </List>
            </Typography>
          </p>
          <br />

          <p>
            <Typography variant="h4">Seguridad</Typography>
            <br />
            <Typography variant="textPrimary">
              KPMG declara implementar medidas de seguridad, técnicas y
              organizativas, apropiadas para garantizar un nivel de seguridad
              adecuado al riesgo, incluyendo entre otros, la capacidad de
              garantizar la confidencialidad, integridad y disponibilidad de la
              información depositada en la Herramienta.
              <br />
              KPMG queda a disposición del Cliente para ofrecerle información
              técnica detallada sobre las medidas de seguridad implementadas por
              KPMG en la Plataforma.
            </Typography>
          </p>
          <br />

          <p>
            <Typography variant="h4">Acceso a la Plataforma</Typography>
            <br />
            <Typography variant="textPrimary">
              El acceso a la Plataforma se realiza mediante un navegador web, a
              través de internet, no siendo necesaria la instalación de software
              en los dispositivos del Cliente y/o Usuarios Autorizados.
              <br />
              KPMG concede al Cliente y/o Usuarios Autorizados una licencia
              limitada y no exclusiva para uso de la Plataforma de conformidad
              con lo establecido en la correspondiente Carta de Encargo.
              <br />
              El acceso a la Plataforma está limitado a las personas
              identificadas por el Cliente y cuyo listado haya sido formalmente
              comunicado a KPMG. Para cada servicio, se permitirá acceso a la
              Plataforma a aquellos profesionales pertenecientes a KPMG, al
              Cliente y Usuarios Autorizados. Como sucede con cualquier producto
              basado en web, los factores técnicos tales como el ancho de banda,
              la configuración de la red y la del navegador del Usuario, pueden
              afectar a la velocidad y accesibilidad de la Plataforma. De
              conformidad con lo anterior, KPMG no puede garantizar al Cliente,
              ni a sus Usuarios un acceso ininterrumpido a la misma. En todo
              caso, KPMG hará sus mejores esfuerzos por devolver la
              disponibilidad a la mayor brevedad posible y podrá realizar
              pruebas antes de su puesta en funcionamiento, en aras a minimizar
              de manera razonable las limitaciones de acceso por parte del
              Cliente y/o Usuarios Autorizados.
              <br />
              KPMG facilitará al Cliente y a los Usuarios Autorizados las
              credenciales de acceso a la Plataforma. Dichas credenciales de
              identificación, inequívoca y unívoca, consisten en un usuario y
              una contraseña compleja (más de ocho (8) caracteres incluyendo
              entre ellos caracteres especiales), ambos intransferibles,
              correspondiendo al Cliente y a los Usuarios Autorizados el deber
              de custodiarlas diligentemente y renovarlas con carácter periódico
              de acuerdo con la correspondiente solicitud del sistema.
              <br />
              Queda expresamente prohibida la cesión de dichas credenciales de
              acceso a terceros no autorizados. Todo acceso y uso de la
              plataforma efectuado con las credenciales facilitadas al Cliente y
              al Usuario, se entenderá llevado a cabo por éste. El Cliente y los
              Usuarios deberán mantener en secreto sus credenciales de acceso,
              así como aplicar los procedimientos necesarios para mantener su
              confidencialidad e impedir su uso no autorizado. En caso de que el
              Cliente o cualquier Usuario tenga sospecha o constancia de que un
              tercero no autorizado ha tenido acceso a las credenciales y/o a la
              plataforma, o de cualquier otro hecho que razonablemente pueda
              afectar a la seguridad de la Plataforma, informará a KPMG a la
              mayor brevedad posible.
              <br />
              El Cliente exime a KPMG de cualquier responsabilidad que pudiera
              deberse a fallos de seguridad de la Plataforma, pérdida de datos o
              de confidencialidad que se deriven de un uso no autorizado de la
              Plataforma por parte del Cliente y/o los Usuarios. KPMG no
              responderá de aquellos ciberataques conocidos como ataque de día
              cero “zero-day attack”, que habiendo implementado todas las
              medidas de seguridad exigibles de acuerdo con este contrato o con
              el estado de la tecnología puedan conllevar un perjuicio para
              alguna de las Partes.
              <br />
              Con carácter general, queda prohibido cualquier uso de la
              Plataforma contrario a lo establecido entre el Cliente y KPMG en
              el presente documento, la correspondiente Carta de Encargo, la
              normativa, así como cualquier acción que afecten o supongan una
              violación de derechos de propiedad intelectual o industrial de
              KPMG o de terceros.
              <br />
              Si la relación entre KPMG y el Cliente llega a su término por
              cualquier motivo, KPMG podrá cancelar el uso de la Plataforma de
              inmediato y desactivar o borrar las cuentas de usuario
              relacionadas. KPMG se reserva el derecho a cancelar el acceso del
              Cliente y de cualquier Usuario a la Plataforma en el caso de que
              tenga sospecha de o llegue a su conocimiento un uso o acceso no
              autorizado de la misma por parte del Cliente o de los Usuarios, o
              el incumplimiento de los términos y condiciones contenidos en el
              presente documento por parte del Cliente o de los Usuarios. En el
              momento en el que se cancele el acceso, el Cliente tomará
              inmediatamente todas las medidas necesarias para suspender el uso
              de la Plataforma por parte de los Usuarios.
            </Typography>
          </p>
          <br />

          <p>
            <Typography variant="h4">
              Propiedad Intelectual e industrial
            </Typography>
            <br />
            <Typography variant="textPrimary">
              Derechos de KPMG: El Cliente y los Usuarios reconocen y aceptan
              que la Plataforma es una herramienta informática titularidad en
              exclusiva de KPMG, quien garantiza ostentar todos los derechos de
              propiedad intelectual e industrial sobre la misma, en calidad de
              titular legítimo autorizado para la ejecución del presente
              documento, y, a excepción de la licencia de uso limitado
              concedida, ni el Cliente ni los Usuarios adquieren derecho de
              ningún tipo sobre o con respecto a la Plataforma.
              <br />
              El Cliente y los Usuarios no puede licenciar, vender o explotar
              ningún derecho de propiedad intelectual o industrial, entre ellos,
              a efectos enunciativos que no limitativos, reproducir, copiar
              total o parcialmente, ceder, transmitir, distribuir, bajo
              cualquier modalidad la licencia concedida ni lucrarse de ningún
              otro modo la Plataforma. El Cliente y los Usuarios también acepta
              no descompilar, desensamblar, aplicar técnicas de ingeniería
              inversa o modificar de otro modo la Plataforma ni piratear o
              realizar cualquier otra acción u operación que tenga por fin
              acceder al código fuente y/o a otros entornos o funcionalidades de
              la Plataforma a las que no se les haya otorgado el acceso.
              <br />
              La puesta a disposición del Cliente y los Usuarios de las
              imágenes, fotografías, patentes, modelos de utilidad e
              industriales, dibujos, gráficos, archivos de texto, audio, vídeo y
              software propiedad de KPMG o de sus proveedores a través de la
              Plataforma o que figuran en el mismo no implica, en ningún caso,
              la cesión de su titularidad o la concesión de un derecho de
              explotación a favor del Cliente y los Usuarios distinto del uso
              que comporta la utilización legítima y acorde con la naturaleza de
              la Plataforma.
              <br />
              Licencia de uso de la Plataforma: KPMG otorga al Cliente y los
              Usuarios una licencia de uso intransferible, no exclusiva y
              temporal sobre la Plataforma conforme a lo establecido en el
              presente documento. La licencia se extiende a aquellas
              actualizaciones correctivas que haga KPMG de la misma.
              <br />
              Marca KPMG en la Plataforma: KPMG y sus logotipos son marcas
              registradas. La utilización de estas marcas requiere la
              autorización expresa de KPMG, así como un contrato de licencia
              acordado expresamente por las partes. El Cliente y los Usuarios se
              comprometen a no hacer uso de aquellos en el tráfico económico sin
              la correspondiente autorización previa y escrita por parte de
              KPMG.
            </Typography>
          </p>
          <br />
          <p>
            <Typography variant="h4">Responsabilidad</Typography>
            <br />
            <Typography variant="textPrimary">
              KPMG adoptará cuantas medidas resulten razonables en relación con
              la continuidad y seguridad de la Plataforma, si bien no garantiza,
              y por tanto no asume responsabilidad respecto a la ausencia de
              suspensiones, interrupciones o limitaciones en la disponibilidad y
              accesibilidad de la aplicación la misma. Asimismo, no asume
              ninguna responsabilidad por los daños que puedan causarse en los
              equipos del Cliente y/o de los Usuarios por posibles virus
              informáticos contraídos por estos a causa de la navegación o uso
              de la Plataforma, o por cualquier otro daño derivado de esa
              navegación o uso.
              <br />
              KPMG no validará, revisará o llevará a cabo ningún proceso de
              auditoría de la información o datos contenidos en las bases de
              datos ni de aquellos facilitados o introducidos en la Plataforma
              por el Cliente y/o los Usuarios, ni expresará opinión alguna al
              efecto. El Cliente responderá de la veracidad, exactitud y
              fiabilidad de la información o datos proporcionados o introducidos
              en la Plataforma por si mismo o por los Usuarios. Si en algún
              momento el Cliente apreciase que parte de dicha información
              pudiera llegar a considerarse incompleta o inexacta, éste deberá
              informar de manera inmediata a KPMG y responderá de las
              consecuencias que puedan derivarse de este hecho ya que la
              introducción de dicha información incompleta o inexacta en la
              misma podría alterar significativamente las conclusiones presentes
              en los entregables objeto de los servicios profesionales acordados
              en la correspondiente Carta de Encargo (los “Entregables”).
              <br />
              El Cliente es el responsable exclusivo de los datos introducidos
              en la Plataforma por los Usuarios. El Cliente velará por que dicho
              contenido cumpla con todo lo dispuesto por las leyes, reglamentos
              y organismos reguladores aplicables; que el contenido no sea
              difamatorio o indecente y que no viole los derechos de privacidad
              de datos o de propiedad intelectual y/o industrial de terceros.
              <br />
              Salvo lo indicado expresamente en la correspondiente Carta de
              Encargo, el Cliente acepta no divulgar, publicar, difundir o poner
              de otro modo a disposición de terceros los Entregables, ni
              incorporar tal contenido a otro trabajo, publicación o sitio web.
              <br />
              El Cliente acepta cumplir con cualesquiera directrices, en materia
              de seguridad, tecnología y gestión de riesgos, que KPMG traslade
              al Cliente en relación al uso de la Plataforma, y trasladarlas en
              su caso a los Usuarios Autorizados. El Cliente acuerda asimismo
              tomar todas las medidas razonables para proteger la seguridad y la
              naturaleza confidencial de la Plataforma – y trasladarlas a los
              usuarios Autorizados - y notificar sin dilación a KPMG si tiene
              sospecha o conocimiento de un evento o acción que podría poner
              razonablemente en peligro la seguridad de la misma.
              <br />
              El Cliente responderá de los daños y perjuicios que pueda sufrir
              KPMG y/o cualesquiera otra de las entidades afiliadas a KPMG
              International Cooperative y/o un tercero como consecuencia del
              incumplimiento imputable al Cliente y/o Usuarios de las
              obligaciones establecidas en el presente documento y/o que les
              vengan impuestas por la legislación vigente en cada momento.
            </Typography>
          </p>
          <br />

          <p>
            <Typography variant="h4">
              Política de Protección de datos
            </Typography>
            <br />
            <Typography variant="textPrimary">
              El tratamiento de datos derivado de los servicios prestados a
              través de la Herramienta quedará sujeto a lo regulado en las
              Condiciones Generales de Contratación que acompañan a la Carta de
              Encargo formalizada entre KPMG y el Cliente, actuando KPMG como
              encargado del tratamiento quien llevará a cabo el tratamiento de
              los datos siguiendo las instrucciones del Cliente como Responsable
              del Tratamiento. KPMG garantiza la adopción de las medidas de
              seguridad exigidas por la normativa en materia de protección de
              datos.
              <br />
              Cómo se tratan los datos de los Usuarios de la Plataforma
              <br />
              Los datos de carácter personal necesarios para la gestión de los
              Usuarios con acceso autorizado a la Plataforma facilitados por el
              Cliente serán tratados por KPMG a los solos efectos referidos. El
              tratamiento de datos será realizado por KPMG en calidad de
              encargado del tratamiento, siéndole por tanto aplicable lo
              dispuesto en el párrafo anterior. Los datos de gestión de los
              Usuarios serán tratados hasta la finalización de los servicios
              prestados a través de la Herramienta de conformidad con los
              términos de la Carta de Encargo formalizada con el Cliente.
            </Typography>
          </p>
          <br />

          <p>
            <Typography variant="h4">
              Generalidades y prevalencia de disposiciones
            </Typography>
            <br />
            <Typography variant="textPrimary">
              El no ejercicio o ejecución por parte de KPMG de cualquier derecho
              o disposición contenida en estos Términos y Condiciones de uso no
              constituirá una renuncia al mismo, salvo que medie reconocimiento
              y acuerdo por escrito por su parte.
              <br />
              En caso de existir discrepancia entre lo establecido en estos
              Términos y Condiciones de uso y las condiciones generales y/o
              particulares de cada servicio específico, prevalecerá lo dispuesto
              en éstas últimas.
              <br />
              En el caso de que cualquier disposición o disposiciones de estos
              Términos y Condiciones de uso fuera(n) considerada(s) nula(s) o
              inaplicable(s), en su totalidad o en parte, por cualquier Juzgado,
              Tribunal u órgano administrativo competente, dicha nulidad o
              inaplicación no afectará a las otras disposiciones de los
              presentes Términos y Condiciones de uso.
            </Typography>
          </p>
          <br />

          <p>
            <Typography variant="h4">Jurisdicción</Typography>
            <br />
            <Typography variant="textPrimary">
              Las relaciones derivadas del uso de la Plataforma se regirán por
              lo dispuesto en la normativa española vigente. Cualesquiera
              controversias y/o litigios se someterán al conocimiento de los
              Juzgados y Tribunales de la ciudad de Madrid, con renuncia expresa
              a cualquier otro fuero que pudiera corresponder.
            </Typography>
          </p>
        </Paper>
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="15vh"
        >
            <Button variant="contained" color="success" onClick={() => acceptTerms()}>Aceptar los términos</Button>
        </Box>
      </Grid>
    );
}

export default Terms;

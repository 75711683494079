import React, { useState, useEffect } from 'react';
import { clienteAxiosNoJWT } from '../config/axios';
import Cookies from 'js-cookie';

const useCSRFToken = () => {
    const [csrftoken, setcsrftoken] = useState('');

    useEffect(() => {
        const fetchcsrftoken = async () => {
            let _csrftoken = Cookies.get('csrftoken')

            console.log('_csrftoken');
            console.log(_csrftoken);
            if (!_csrftoken) {
                try {
                    const respuesta = await clienteAxiosNoJWT.get('/auth/csrf_cookie');
                } catch (err) {
                    console.log({ msg: 'Try to get csrf_cookie err:', err });
                }
            }
            else {
                _csrftoken = Cookies.get('csrftoken')
                setcsrftoken(_csrftoken);
            }
        }
        fetchcsrftoken();
    }, []);

    return { csrftoken };
};

export default useCSRFToken;
import axios from "axios";
import useCSRFToken from "../hooks/useCSRFToken";

const clienteAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,

  headers: {
    "Content-Type": "application/json",
  },
});

//interceptor para añadir jwt al header
clienteAxios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token_access");
    if (token) {
      config.headers["Authorization"] = "JWT " + token;
    }

    try {
      const { csrftoken } = useCSRFToken();
      config.headers["X-CSRFToken"] = csrftoken;
    } catch (e) {
      console.log(e);
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//interceptor para conseguir nuevo token
clienteAxios.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (originalConfig.url !== "/auth/signin" && err.response) {
      // Access Token expired
      if (err.response.status === 401 && !originalConfig._retry) {
        if (!originalConfig._retry) {
          originalConfig._retry = true;
          try {
            const rs = await clienteAxiosNoJWT.post("/auth/token/refresh", {
              refresh: localStorage.getItem("token_refresh"),
            });

            const accessToken = rs.data.access;
            localStorage.setItem("token_access", accessToken);
            return clienteAxios(originalConfig);
          } catch (_error) {
            window.location.href = "/login";
            //return Promise.reject(_error);
          }
        }
      } else if (err.response.status === 403) {
        window.location.href = "/";
      }
    }
    return Promise.reject(err);
  }
);

const clienteAxiosNoJWT = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export { clienteAxios, clienteAxiosNoJWT };

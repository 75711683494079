import React, {Fragment, useState} from 'react';
import FormInput from '../FormInput';
import useForm from '../../hooks/useForm';
import useAuth from '../../hooks/useAuth';
import {Link} from 'react-router-dom';
import Alert from '@mui/material/Alert';

import './login.css'

const Login = () => {

    const { values, handleChange } = useForm({
        initialValues: {
            username: '',
            password: ''
        }
    });

    const { loginUser, error } = useAuth();
    const [errorForm, setErrorForm] = useState(false)

    const handleLogin = async (e) => {
        e.preventDefault();
        if (errorForm) {setErrorForm(false)}
        if (values.username.length > 0 && values.password.length > 0 ) {
            await loginUser(values);
        } else setErrorForm(true)
    }

    return (
        <Fragment>
            <form onSubmit={handleLogin}>
            <section className="Login">
                <h3>Login</h3>
                <div>
                    <FormInput
                            type="text"
                            name="username"
                            value={values.username}
                            placeholder = 'Usuario'
                            handleChange={handleChange}
                    />
                    <FormInput
                            type="password"
                            name="password"
                            value={values.password}
                            placeholder = 'Contraseña'
                            handleChange={handleChange}  
                    />
                    <Link to="/forgot-password"><small>Olvidé mi contraseña</small></Link>
                </div>
                <div>
                    <span> 
                        <div>
                            <button className="btn" type='submit'>login</button>
                        </div>
                    </span>
                </div>
                {
                    errorForm &&
                        <div style={{marginTop: 10}}>
                            <Alert 
                                severity="warning"
                            >
                               
                            Rellene todos los campos.</Alert>
                        </div>
                }
                {
                    error &&
                        <div style={{marginTop: 10}}>
                            <Alert severity="error">Error de login</Alert>
                        </div>
                }
            </section>
            </form>
        </Fragment>
    );
}

export default Login
import React, {Fragment, useState} from 'react';

const useSelector = (label, stateInicial, opciones, disabled = false, size = '') => {

    // State de nuestro custom hook
    const [state, actualizarState] = useState(stateInicial);

    let classSize = 'custom-select';
    const sizes = ['lg', 'sm'];
    if (sizes.includes(size)) {
        classSize = classSize + ' ' + classSize  + '-' + size;
    }

    const SelectSector = () => (
        <Fragment>
            <label>{label}</label>
            <select 
                className={classSize}
                onChange={ e => actualizarState(e.target.value)}
                value={state}
                disabled= {disabled}
            >
                <option value="">- Seleccione -</option>
                {opciones.map(opcion => (
                    <option key={opcion.id} value={opcion.id} disabled={ opcion.disabled }>{opcion.name}</option>
                ))}
            </select>
        </Fragment>
    );

    // Retornar state, interfaz y fn que modifica el state
    return [state, SelectSector, actualizarState];
}
 
export default useSelector;
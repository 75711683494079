import React from "react";
import { CSSTransition } from "react-transition-group";
import ReactTransitionGroup from "react-addons-transition-group";

class Text extends React.Component {
  render() {
    return (
      <div style={{ ...this.props.style }}>
        <div className={`content ${this.props.text ? "open" : ""}`}>
          {this.props.text ? this.props.children : ""}
          {this.props.text
            ? this.props.render && this.props.render(this.props.text)
            : ""}
        </div>
      </div>
    );
  }
}

export default Text;

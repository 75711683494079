import React from 'react';
import { Link } from "react-router-dom";
import { FaSignOutAlt } from "react-icons/fa";

import './SignOut.css'

const SignOut = ()=> {

    const logout = async(e) => {
        e.preventDefault();
        localStorage.clear();
        window.location.href = '/login';
    }

    return (
        <Link  to="../login" onClick={logout} className="linkWhite">
            <div className='containerMenu'>
                <div className='icon'>
                    <FaSignOutAlt/> 
                </div>
                <div className='text'>
                    <p>Cerrar sesión</p>
                </div>
            </div>
        </Link>
    );
}


export default SignOut;
import {React, Fragment, useEffect, useState, useMemo} from 'react';
import { Link } from "react-router-dom";
import ReactDOM from 'react-dom';
import { FaUserFriends,FaRegCopy, FaPoll, FaQuestionCircle } from "react-icons/fa";
import SignOut from '../../UI/SignOut/SignOut';
import './frequentQuestions.css'
import Text from "./text";
import Heading from "./heading";
import getAccordion from "./getAccordion";

const Accordion = getAccordion(1);

const FrequentQuestions = () => {
    return(
    <div className="frequentQuestion">
        <h1>Preguntas Frecuentes</h1>
        <Accordion>
            
                <Heading
                    render={icon => <div className={`icon ${icon && "open"}`}>➡️</div>}
                >
                    <div className="heading-box">
                    <h1 className="heading">1 - ¿Cómo puedo activar mi cuenta?</h1>
                    </div>
                </Heading>
                <Text>
                    <p className="text">
                    
                    </p>
                </Text>
            
        
           
                <Heading
                    render={icon => <div className={`icon ${icon && "open"}`}>➡️</div>}
                >
                    <div className="heading-box">
                    <h1 className="heading">2- ¿Cómo puedo entrar en la plataforma?</h1>
                    </div>
                </Heading>
                <Text>
                    <p className="text">
                   Para entrar en la plataforma el usuario tiene que introducir su usuario (E-Mail) y su contraseña. Con un "Click" en el botón de "LOGIN" se inicia el "Login" y el usuario puede entrar en la plataforma. En caso de que el usuario no recuerde su contraseña, en la misma pantalla de "Login", tiene un enlace de "olvidó su contraseña", donde podrá hacer click y recuperar su contraseña.
                    </p>
                </Text>
                <Heading
                    render={icon => <div className={`icon ${icon && "open"}`}>➡️</div>}
                >
                    <div className="heading-box">
                    <h1 className="heading">3- ¿Cómo puedo subir ficheros?</h1>
                    </div>
                </Heading>
                <Text>
                    <p className="text">
                   Para subir ficheros debemos seguir estos pasos:
                   <br/>1- Acceder a la vista "Documentos".
                   <br/>2- Hacer click en "Añadir documento", en la esquina superior derecha de la tabla.
                   <br/>3- Seleccionar en cada una de las listas su "Organización" y "Empresa" correspondiente.
                   <br/>4- Seleccionaremos "Documentación relativa al cliente" o "Documentación relativa a vehículos".
                   <br/>5- En "Documentación relativa al cliente", se podrá adjuntar el "Contrato" y la "Documentación adicional".
                   <br/>6- En "Documentación relativa a vehículos", seleccionaremos el vehículo sobre el que adjuntaremos Documentación, y se podrá adjuntar el "Documento de compra", la "Ficha técnica" y el "Permiso de circulación"
                   <br/>

                    </p>
                </Text>
                <Heading
                    render={icon => <div className={`icon ${icon && "open"}`}>➡️</div>}
                >
                    <div className="heading-box">
                    <h1 className="heading">4- ¿Cómo puedo editar información de mi cuenta?</h1>
                    </div>
                </Heading>
                <Text>
                    <p className="text">
                   

                    </p>
                </Text>
                <Heading
                    render={icon => <div className={`icon ${icon && "open"}`}>➡️</div>}
                >
                    <div className="heading-box">
                    <h1 className="heading">5- ¿Qué hago si he olvidado mi contraseña?</h1>
                    </div>
                </Heading>
                <Text>
                    <p className="text">
                    En caso de que haya olvidado su contraseña, puede solicitar una nueva en la página de acceso haciendo click en el botón, "Ólvido su contraseña".


                    </p>
                </Text>
                <Heading
                    render={icon => <div className={`icon ${icon && "open"}`}>➡️</div>}
                >
                    <div className="heading-box">
                    <h1 className="heading">6- ¿Dónde puedo consultar información sobre los documentos que ya he subido?</h1>
                    </div>
                </Heading>
                <Text>
                    <p className="text">
                    Desde la página de inicio, puede ir a la página de resumen de documentos haciendo click en "Documentos".
                    </p>
                </Text>
                <Heading
                    render={icon => <div className={`icon ${icon && "open"}`}>➡️</div>}
                >
                    <div className="heading-box">
                    <h1 className="heading">7- ¿Cómo puedo cerrar sesión?</h1>
                    </div>
                </Heading>
                <Text>
                    <p className="text">
                    Para realizar un cierre de sesión limpio, por favor haga click en el botón de cierre de sesión. Después de que se realice el cierre de sesión, todos los datos almacenados en la memoria caché se borrarán automáticamente de su ordenador.

                    </p>
                </Text>
                <Heading
                    render={icon => <div className={`icon ${icon && "open"}`}>➡️</div>}
                >
                    <div className="heading-box">
                    <h1 className="heading">8- Después de entrar en la plataforma he estado inactivo durante un tiempo. ¿Porqué tengo que hacer el login de nuevo?</h1>
                    </div>
                </Heading>
                <Text>
                    <p className="text">
                    Si no hay actividad en tu cuenta durante un periodo de tiempo prolongado (15 minutos), deberás volver a iniciar sesión con su email y contraseña.
                    <br/>Por política de seguridad, al ser una plataforma con información privada debemosprevenir acceso no autorizado. Por ejemplo, si fuese a dejar el ordenador encendido con la página activa en un espacio público nadie podrá iniciar sesión sin su información.
                    </p>
                </Text>
                <Heading
                    render={icon => <div className={`icon ${icon && "open"}`}>➡️</div>}
                >
                    <div className="heading-box">
                    <h1 className="heading">9- ¿Cómo puedo saber con que usuario me he conectado?</h1>
                    </div>
                </Heading>
                <Text>
                    <p className="text">
                    Podemos identificar el usuario conectado mediante el email utilizado para acceder a la plataforma, este se mostrará en todo momento en la parte superior derecha de la pantalla.

                    </p>
                </Text>
                <Heading
                    render={icon => <div className={`icon ${icon && "open"}`}>➡️</div>}
                >
                    <div className="heading-box">
                    <h1 className="heading">10- ¿Cuántos documentos puedo subir a la vez?</h1>
                    </div>
                </Heading>
                <Text>
                    <p className="text">
                    La plataforma permite subir X documentos a la vez.

                    </p>
                </Text>
                <Heading
                    render={icon => <div className={`icon ${icon && "open"}`}>➡️</div>}
                >
                    <div className="heading-box">
                    <h1 className="heading">11- ¿Cómo puedo dar de alta un cliente?</h1>
                    </div>
                </Heading>
                <Text>
                    <p className="text">
                    Para dar de alta un nuevo cliente debe seguir estos pasos:
                   <br/>   1- Acceder en el menú principal a la vista "Gestión clientes". 
                   <br/>    2- Hacer click en el icono "+ Añadir cliente", en la esquina superior derecha de la tabla.
                   <br/>    3- Al terminar de completar los datos del nuevo cliente hacemos click en "Añadir"<br/>*NOTA: debe rellenar obligatoriamente todos los campos en rojo
                    <br/> *Podemos dar de alta un nuevo cliente haciendo click en el icono "+ Añadir cliente" o "Añadir empresa" en la pantalla de "Subir documentos"
                    </p>
                </Text>
                <Heading
                    render={icon => <div className={`icon ${icon && "open"}`}>➡️</div>}
                >
                    <div className="heading-box">
                    <h1 className="heading">12- ¿Cómo puedo volver a la página principal?</h1>
                    </div>
                </Heading>
                <Text>
                    <p className="text">
                    En la esquina superior izquierda se muestra una imagen en forma de casa que al hacer click en ela nos llevará directamente a la página principal.

                    </p>
                </Text>
                <Heading
                    render={icon => <div className={`icon ${icon && "open"}`}>➡️</div>}
                >
                    <div className="heading-box">
                    <h1 className="heading">13- Verificar Clientes</h1>
                    </div>
                </Heading>
                <Text>
                    <p className="text">
                   

                    </p>
                </Text>
                <Heading
                    render={icon => <div className={`icon ${icon && "open"}`}>➡️</div>}
                >
                    <div className="heading-box">
                    <h1 className="heading">14- ¿Cuál es el límite de tamaño por fichero subido?</h1>
                    </div>
                </Heading>
                <Text>
                    <p className="text">
                    La limitación de los documentos subidos es que su tamaño sea menor de 100MB

                    </p>
                </Text>
                <Heading
                    render={icon => <div className={`icon ${icon && "open"}`}>➡️</div>}
                >
                    <div className="heading-box">
                    <h1 className="heading">15- ¿Cuál es la resolución máxima permitida de los documentos subidos a la plataforma?</h1>
                    </div>
                </Heading>
                <Text>
                    <p className="text">
                    No existe una restricción de la resolución máxima de los documentos, cuanto mayor sea la resolución mejor.
                    <br/>NOTA: La única limitación de los documentos subidos es que su tamaño sea menor de 100MB.

                    </p>
                </Text>
                <Heading
                    render={icon => <div className={`icon ${icon && "open"}`}>➡️</div>}
                >
                    <div className="heading-box">
                    <h1 className="heading">16- Información sobre documentos del vehículo</h1>
                    </div>
                </Heading>
                <Text>
                    <p className="text">
                    
                    <br/><b>Factura Compra</b>: Documento en el que se detalla el coche/producto que ya se ha pagado. La cuantía económica debe venir reflejada.
                    <br/>
                    <br/><b>Contrato Leasing</b>: Documento en el que se expone el arrendamiento financiero. Vendrá la cuantía financiada, en cuántas cuotas, y con qué interés. Suele venir cuadro de amortización.
                    <br/>
                    <br/><b>Pedido o Factura Proforma</b>: Documento que refleja precio al que se realizaría la operación, pero no realizada todavía.
                    <br/>
                    <br/><b>Recibo Concesionario</b>: Cualquier tipo de documento ofrecido por el concesionario sobre el vehículo. Suelen ser recibos de cuantías abonadas.
                    <br/>
                    <br/><b>Justificante o Extracto Bancario</b>: Cualquier tipo de documento ofrecido por una entidad bancaria sobre el vehículo. Suelen ser recibos de cuantías abonadas.
                    <br/>
                    <br/><b>Impuesto Especial</b>: Cualquier tipo de impuesto diferente al de matriculación.
                    <br/>
                    <br/><b>Impuesto Matriculación</b>: Documento que expone la cantidad del impuesto de matriculación del coche.
                    <br/>
                    <br/><b>Ficha Activos</b>: Listado individualizado de cada activo que posee la empresa. Suele venir reflejado en uno de los registros el valor del coche.
                    <br/>
                    <br/><b>Ficha Técnica</b>: Documento oficial, expedido por el Ministerio de Industria. Identifica el vehículo y su homologación para circular por las carreteras del país.
                    <br/>
                    <br/><b>Contabilidad</b>: Listado de las cuentas de la empresa. Suele venir reflejado en uno de los registros el valor del coche.
                    <br/>
                    <br/><b>Registro de Bienes Muebles</b>: Listado de los bienes que posee una empresa o particular. Suele venir reflejado el vehículo como un bien más.
                    <br/>
                    <br/><b>Certificado DGT/Certificado Registro Vehículo</b>: Informe en el que se certifica, por parte de la DGT, que un Vehículo es propiedad de alguien en particular.
                    <br/>
                    <br/><b>Informe Agencia Tributaria</b>: Informe expedido por la Agencia Tributaria que refleja cualquier transacción conocida del vehículo.
                    <br/>
                    <br/><b>Informe Vida Vehículo/Informe Vehículo DGT</b>: Informe expedido por la DGT, mediante previa solicitud. Incluye tanto campos técnicos como un historial del vehículo y sus revisiones.
                    <br/>
                    <br/><b>Permiso de Circulación</b>: Documento oficial, identifica la titularidad de cualquier tipo de vehículo. También certifica que el vehículo está matriculado.
                    <br/>
                    <br/><b>OTROS</b>: Cualquier otro tipo de documento que no se puede catalogar como los anteriores.

                    </p>
                </Text>
                <Heading
                    render={icon => <div className={`icon ${icon && "open"}`}>➡️</div>}
                >
                    <div className="heading-box">
                    <h1 className="heading">17- Tipos de contrato</h1>
                    </div>
                </Heading>
                <Text>
                    <p className="text">
                    <br/><b>Contrato a nombre de Cónyuge:</b>  El vehículo se ha adquirido por un cónyuge y se ha puesto a nombre del otro cónyuge.
                    <br/>
                    <br/><b>Contrato a nombre de Familiar:</b>  La compra del vehículo se ha realizado por alguno de los padres y el vehículo se ha puesto a nombre de otro miembro de la familia.
                    <br/>
                    <br/><b>Contrato Régimen Gananciales:</b> El vehículo se ha adquirido en régimen de gananciales, en el que el reclamante está actualmente divorciado habiéndose quedado con la propiedad del vehículo y no se ha asignado el vehículo en el convenio regulador.
                    <br/>

                    <br/>En todos estos casos, deben ceder ambos (cónyuges, padre e hijos, …). 

                    </p>
                </Text>
        </Accordion>
    </div>
    )
}

export default FrequentQuestions
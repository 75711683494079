import React, { useEffect, useState, useMemo, Fragment, useContext } from 'react';
import useFetchApi from '../../hooks/useFetchApi';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Box from "@material-ui/core/Box";
import DocumentDownloadTable from './DocumentDownloadTable';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useParams } from "react-router-dom";
import {clienteAxios} from '../../config/axios'
import PageTable from '../../UI/BaseTable/PageTable'
import useForm from '../../hooks/useForm';

import "./documents.css"

const ValidateDocs = () => {
    const navigate = useNavigate();
	const [{ elements, totalElements, isLoading, isError }, doFetch] = useFetchApi();
    const { company_id } = useParams();
    const [incidents, setIncidents] = useState([]);

	useEffect(() => {
        const getIncidents = async () => {
            try {
                const result = await clienteAxios.get('compute/getCompanyIncidents/' + company_id);
                setIncidents(result.data);
            } catch (error) {
                console.log(error);
            }
        };
		
		doFetch({url: 'compute/getCompanyFiles/' + company_id, params: {}})
        getIncidents();
	    
	}, [])

    const { values, handleChange, setValues } = useForm({
		initialValues: {
			message: '',
            company_id: company_id
		}
	});

    const validateCompany = async () => {

        var config = {
            method: 'get',
            url: 'compute/validateCompany/' + company_id,
        };
          
        clienteAxios(config)
        .then((result) => {
            alert('Cliente validado');
            navigate('/validatedocs')
        })
        .catch((error) => {
            alert('Error');
            console.log(error);
        })
    };

    const createIncident = async () => {

        var data = new FormData();
        Object.entries(values).forEach(([key, value]) => {
            console.log(key + ' ' + value);
            data.append(key, value);
        });

        var config = {
            method: 'post',
            url: 'compute/createIncident',
            data : data
        };
          
        clienteAxios(config)
        .then((result) => {
            alert('Elemento guardado');
            const timeElapsed = Date.now();
            const today = new Date(timeElapsed);
            let newIncident = {"message": values.message, "ts_created": today.toLocaleDateString(), "ts_solved":"No resuelto"}

            setIncidents(oldArray => [...oldArray, newIncident])
            setValues({message: '',company_id: company_id})
        })
        .catch((error) => {
            console.log(error);
        })
    };

    const headers = [
        { 
          id: 'file', 
          label: 'Nombre del archivo', 
          minWidth: 250,
          link: 'download'
        },
        {
          id: 'document_species',
          label: 'Tipo de documento',
          minWidth: 150,
          
          //align: 'right',
        },
        {
            id: 'ts_upload',
            label: 'Fecha de carga',
            minWidth: 150,
            
            //align: 'right',
          }

    ];


    const headersIncidents = [
        { 
          id: 'message', 
          label: 'Mensaje', 
          minWidth: 100,
        },
        {
          id: 'ts_created',
          label: 'Fecha creación',
          minWidth: 50,
          align: 'right',
        },
        {
          id: 'ts_solved',
          label: 'Fecha resolución',
          minWidth: 50,
          align: 'right',
        }
    ];
  

	return (
        <div className='containerTable'>
            <div className='headerSearch'>
				<div>
					<h3>Validación de documentos:</h3>
				</div>
          	</div>
		<Fragment>
			{(isLoading) &&
				<Box
				  display="flex"
				  justifyContent="center"
				  alignItems="center"
				  minHeight="100vh"
				>
					<CircularProgress 
						size={80}
						sx={{
							position: 'absolute',
							top: '50%',
							left: '50%',
							marginTop: '-12px',
							marginLeft: '-12px',
						}}
					/>
				</Box>
            }

            { (!isLoading && Object.keys(elements).length > 0) &&
                
                <Fragment>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <div className='headerSearch'>
                                <div>
                                    <h3>Documentos de la empresa: <b>{elements.name}</b></h3>
                                </div>
                            </div>
                            <DocumentDownloadTable 
                                name = {elements.name}
                                columns = {headers}
                                elements = {elements.files}
                            />
                        </Grid>
                        <Grid item xs={9}>
                            <Box minHeight="30vh">
                                <TextField 
                                    fullWidth multiline maxRows={4}  
                                    id="message"
                                    name="message"
                                    style={{backgroundColor: "white"}}
                                    onChange={handleChange}
                                    value={values.message}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={3}>
                            <Box minHeight="30vh">
                            <Button fullWidth variant="contained" disabled={ elements.is_verified } color="info" onClick={() => createIncident()}>Añadir incidencia</Button>
                            </Box>
                        </Grid>
                    </Grid>

                    { incidents.length > 0 &&
                        <div className='table'>
                            <span>El expediente presenta incidencias</span>
                            <PageTable 
                                headers = { headersIncidents } 
                                elements = { incidents }
                            />
                        </div>
                    }

                     
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            minHeight="15vh"
                        >
                            <Button variant="contained" disabled={ elements.is_verified || incidents.length > 0 } color="success" onClick={() => validateCompany()}>Validar empresa</Button>
                        </Box>

                </Fragment>
            }
            
		</Fragment>
        </div>
		);
	};


export default ValidateDocs
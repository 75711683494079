import React, { useEffect, useState, useMemo, Fragment } from 'react';
import useSelector from '../../hooks/useSelector';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import useFetchApi from '../../hooks/useFetchApi';
import {clienteAxios} from '../../config/axios';
import CarFiles from '../CarFiles';
import ClientFiles from '../ClientFiles';
import ClientModal from '../modals/ClientModal'
import CarModal from '../modals/CarModal'
import { FaPlusCircle } from "react-icons/fa";
import CircularProgress from '@mui/material/CircularProgress';
import Box from "@material-ui/core/Box";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import "./addDocuments.css"


const AddDocuments = () => {

	const [{ elements, totalElements, isLoading, isError }, doFetch] = useFetchApi();
	const [addedCar, setAddedCar] = useState(false);
	const [addedCompany, setAddedCompany] = useState(false);
	const [car, setCar] = useState(null);
	const [listaEntity, setListaEntity] = useState([])
	const [listaCompany, setListaCompany] = useState([]);
	const [listaDocumentos, setListaDocumentos] = useState([]);
	const [listaDocumentosCoche, setListaDocumentosCoche] = useState([]);
	const [listaDocumentosCompany, setListaDocumentosCompany] = useState([]);
	const [listaCoche, setListaCoche] = useState([]);
	const [id_entity, SelectEntity, setIdEntity] = useSelector('Organización', '', listaEntity);
	const [id_company, SelectCompany, setIdCompany] = useSelector('Empresa', '', listaCompany);
	const [id_coche, SelectCoche, setIdCoche] = useSelector('Vehículo', '', listaCoche);
	const [tipoDocCoche, SetTipoDocCoche] = useState('');
	const [showModalCliente, setShowModalCliente] = useState(false);
	const [showModalCoches, setShowModalCoche] = useState(false);
	const [tabValue, setTabValue] = useState(0);

	const carTypeDocumentsList = [];
	const companyTypeDocumentsList = [];

	const getDocumentTypes = async () => {
        let urlLocalizacion1 = 'compute/documentSpecies';
        try {
            const docs = await clienteAxios.get(urlLocalizacion1);
            setListaDocumentos(docs.data[0].result);
			
		} catch (error) {
            console.log(error);
            setListaDocumentos([]);
        }
    }

    useEffect(() => {
        getDocumentTypes()
    }, [])

	useEffect(() => {
		for (let i = 0; i < listaDocumentos.length; i++) {
			let element = listaDocumentos[i];
			console.log(element.value)
			if (element.value === "Contrato" || element.value === "Documentos adicionales" || element.value === "Pago del Euro" || element.value === "Contrato Regimen Gananciales" || element.value === "Contrato a nombre de Conyuge" || element.value === "Contrato a nombre de Familiar") {
				companyTypeDocumentsList.push(element);
			} else {
				if (element.value !== "Documento de compra" && element.value !== "Documento adicional"){
					carTypeDocumentsList.push(element);
				}
			}
		}

		setListaDocumentosCoche(carTypeDocumentsList);
		setListaDocumentosCompany(companyTypeDocumentsList);

	}, [listaDocumentos])


	const getEntities = async () => {
        let urlLocalizacion1 = 'compute/getEntities';
        try {
            const entity = await clienteAxios.get(urlLocalizacion1);
            setListaEntity(entity.data);

			if (entity.data.length == 1) {
				setIdEntity(entity.data[0].id)
			}
        } catch (error) {
            console.log(error);
            setListaEntity([]);
        }
    }

	const getCompanies = async () => {
        let urlLocalizacion1 = `compute/getEntityCompanies/${id_entity}`;
        try {
            const companies = await clienteAxios.get(urlLocalizacion1);
            setListaCompany(companies.data);
			setAddedCompany(false)
        } catch (error) {
            console.log(error);
            setListaCompany([]);
        }
    }

	const getCars = async () => {
        let urlLocalizacion1 = `compute/getCompanyCarsList/${id_company}`;
        try {
            const cars = await clienteAxios.get(urlLocalizacion1);
            setListaCoche(cars.data);
			setAddedCar(false)
        } catch (error) {
            console.log(error);
            setListaCoche([]);
        }
    }

	useEffect(() => {

		if (id_coche.length !== 0) {
		  setCar(id_coche)
		} else {
			setCar(null)
		}

	}, [id_coche]);


	//lista de coches
	useEffect(() => {
		
		setIdCoche('')
		setCar(null)
		setTabValue(0);
		if (id_company.length !== 0) {
		  getCars()
		  doFetch({url: 'compute/getCompanyFiles/' + id_company, params: {}})
		}
  
	}, [id_company, addedCar]);

	//lista de compañias
	useEffect(() => {

		setIdCoche('')
		setIdCompany('')


		if (id_entity.length !== 0 ) {
			getCompanies()
		}
	
	}, [id_entity, addedCompany]);

	//lista de entidades
	useEffect(() => {
		getEntities()
	}, []);

	//coche con sus ficheros
	useMemo(() => {
		if (car) {
			doFetch({url: 'compute/getCar/' + car, params: {}})
		}
		
	}, [car])

	//company con sus ficheros

	const handleCloseModalCliente = () => {setShowModalCliente(false);}
	const handleShowModalCliente = () => {setShowModalCliente(true);}

	const handleCloseModalCoche = () => {setShowModalCoche(false);}
	const handleShowModalCoche = () => {setShowModalCoche(true);}

	const handleTabChange = (event, newValue) => {
		setTabValue(newValue);
		if (newValue == 0) {
			setIdCoche('')
			setCar(null)
			if (id_company.length !== 0) {
				getCars()
				doFetch({url: 'compute/getCompanyFiles/' + id_company, params: {}})
			}
		}
	};

    return (
		<Fragment>
		  <Grid
			container
			direction="row"
			justify="flex-start"
			alignItems="flex-start"
			aligncontent="flex-start"
			spacing={4}
			className= "containerAddDocument"
		  >
			<Grid item xs={12} className="titAddDocument">
			  <Typography>
				<h3>Subida de ficheros</h3>
			  </Typography>
			  <div className='divAddDocument'>
				<div className='addDocument' type='button' onClick={() =>handleShowModalCliente()}>
				<div> <FaPlusCircle/> </div> 
				<div> <p>Añadir empresa</p> </div>
         
				</div>
				<div className='addDocument' type='button' onClick={() =>handleShowModalCoche()}>
					<div> <FaPlusCircle/> </div> 
					<div> <p>Añadir vehículo</p> </div>
				</div>
			</div>
			  {/* <Divider variant="insert" /> */}
			</Grid>
			<div className='optionAddDocument'>
				<Grid item><div ><SelectEntity/></div></Grid>
				<Grid item><div ><SelectCompany/></div></Grid>
			</div>
			
			<Divider />
			
			
			{
				id_company &&
					<Box sx={{ width: '100%', bgcolor: 'background.paper' }} className="boxMargin">
						<Tabs value={tabValue} onChange={handleTabChange} centered>
							<Tab label="Documentación relativa al cliente" />
							<Tab label="Documentación relativa a vehículos" />
						</Tabs>
					</Box>
			}

		  	{(isLoading) &&
				<Box
				  display="flex"
				  justifyContent="center"
				  alignItems="center"
				  minHeight="100vh"
				  
				>
					<CircularProgress 
						size={80}
						sx={{
							position: 'absolute',
							top: '50%',
							left: '50%',
							marginTop: '80px',
							marginLeft: '-12px',

						}}
					/>
				</Box>
            }

			{(!car && id_company && !isLoading && Object.keys(elements).length > 0  && tabValue == 0) && 
				<Fragment>
					<ClientFiles 
						uploadedList = {elements.files}
						listaDocumentos = {listaDocumentosCompany}
						company = {id_company}
					/> 
				</Fragment>
			}

			{(Object.keys(elements).length > 0 && !isLoading && tabValue == 1) && 
				<Fragment>
					<div className='optionAddDocument'>
						<Grid item><div ><SelectCoche/></div></Grid>
					</div>

					{car &&
						<Fragment>
							<div className='optionAddDocument'>
								<label>Tipo de documento</label>
								<select 
									onChange={ e => SetTipoDocCoche({id: e.target.value, value: e.target.selectedOptions[0].text})}
									value={tipoDocCoche.id}
								>
									<option value="">- Seleccione -</option>
									{listaDocumentosCoche.map(opcion => (
										<option key={opcion.id} value={opcion.id}>{opcion.value}</option>
									))}

								</select>
								<br/>(Para más información sobre el tipo de documento, consultar punto 16 de Preguntas Frecuentes)
							</div>
							
							{ tipoDocCoche &&
								<CarFiles 
									uploadedList = {elements.files}
									car = {id_coche}
									document_type_name = {tipoDocCoche.value}
									document_type_id = {tipoDocCoche.id}
								/>
							}
						</Fragment>
						}
				</Fragment>
			}

			{
				showModalCliente && 
            	<Fragment>
              		<ClientModal
                		isOpen={showModalCliente}
                		onRequestClose={handleCloseModalCliente}
						onSave = {setAddedCompany}
              		/>
            	</Fragment>
			}

			{
				showModalCoches && 
            	<Fragment>
              		<CarModal
                		isOpen={showModalCoches}
                		onRequestClose={handleCloseModalCoche}
						onSave = {setAddedCar}
              		/>
            	</Fragment>
			}

		  </Grid>
		</Fragment>
	  );
	};


export default AddDocuments
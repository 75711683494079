import React, {Fragment, useState} from 'react';
import FormInput from '../FormInput';
import useForm from '../../hooks/useForm';
import { useParams } from "react-router-dom";
import {clienteAxiosNoJWT} from '../../config/axios'
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';

import './login.css'

const ResetPassword = () => {

    const navigate = useNavigate();
    const [errorForm, setErrorForm] = useState(false)
    const { uid , key } = useParams();
    const { values, handleChange } = useForm({
        initialValues: {
            password: '',
            password_confirm: ''
        }
    });

    const handleLogin = async (e) => {
        e.preventDefault();

        if (values.password.length > 0 && values.password == values.password_confirm) {
            return clienteAxiosNoJWT.post('auth/reset_password_confirm', {
                uidb64: uid,
                token: key,
                password: values.password
            }).then(async (result) => {
                navigate('/login');
            }).catch((err) => {
                console.log('Se ha producido un error')
            })
        } else setErrorForm(true)
    }

    return (
        <Fragment>
            <form onSubmit={handleLogin}>
            <section className="Login">
                <h5>Introduzca su nueva contraseña</h5>
                <div>
                    <FormInput
                            type="password"
                            name="password"
                            value={values.password}
                            placeholder = 'Nueva contraseña'
                            handleChange={handleChange}
                    />
                    <FormInput
                            type="password"
                            name="password_confirm"
                            value={values.password_confirm}
                            placeholder = 'Confirme contraseña'
                            handleChange={handleChange}  
                    />
                </div>
                <div>
                    <span> 
                        <div>
                            <button className="btn" type='submit'>enviar</button>
                        </div>
                    </span>
                </div>
                {
                    errorForm &&
                    <div style={{marginTop: 10}}>
                        <Alert 
                            severity="warning"
                        >
                        Los campos no pueden estar vacíos y deben coincidir
                        </Alert>
                    </div>
                }
            </section>
            </form>
        </Fragment>
    );
}

export default ResetPassword
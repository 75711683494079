import {React, Fragment, useMemo} from 'react';
import { Link } from "react-router-dom";
import { FaUserFriends,FaRegCopy, FaPoll, FaQuestionCircle } from "react-icons/fa";
import './menu.css'
import EnhancedTable from '../../UI/BaseTable/EnhancedTable'
import useFetchApi from '../../hooks/useFetchApi';
import {UserContext} from '../../hooks/UserContext';
import {clienteAxios} from '../../config/axios';
import { BiDuplicate, BiUserCheck } from "react-icons/bi";


const Menu = () => {
    const [{ elements, totalElements, isLoading, isError, setElements }, doFetch] = useFetchApi();

    useMemo(() => {
		doFetch({url: 'compute/getActiveIncidents', params: {}})
	}, [])

    const markAsCompleted = (id) => {
        if (window.confirm('Una vez marcada como resuelta se volverá a cerrar el cliente ¿Ha remito la documentación asociada a la incidencia?')) {
            var config = {
                method: 'get',
                url: 'compute/closeIncident/' + id,
            };
            
            clienteAxios(config)
            .then((result) => {
                const remaining = result.data.remaining;
                setElements(elements.filter(item => item.id !== id))

                if (0 == remaining) {
                    alert('Todas las incidencias del cliente resueltas, se cierra para su validación')
                } else {
                    alert('Incidencia resuelta')
                }
            })
            .catch((error) => {
                alert('Error');
                console.log(error);
            })
        }
    }


    const headers = [
        {
            id: 'entity',
            label: 'Organización',
            minWidth: 50,
            align: 'right',
            
        },
        {
          id: 'company',
          label: 'Empresa',
          minWidth: 50,
          align: 'right',
        },
        { 
            id: 'message', 
            label: 'Mensaje', 
            minWidth: 100
        },
        {
          id: 'ts_created',
          label: 'Fecha',
          minWidth: 50,
          align: 'right'
          
        },
        {
            id: 'actions',
            label: 'Acciones',
            minWidth: 50,
            align: 'right'
            
          },
      ];
   
    return (
    <Fragment>
    <section >
        <div className="Menu">
            <Link to="../documents">
                <div className='optionsMenu'>
                    <div className='icon'>
                        <FaRegCopy />
                    </div>
                    <p>Documentos</p>
                    {/* <Image  style={{width: 30, height: 30}} source={require('../../../public/img/customer.png')}  /> */}
                    {/* <img src={"../../../public/img/customer.png"} /> */}
                    
                </div>
            </Link>

            <Link to="../add-documents">
                <div className='optionsMenu'>
                    <div className='icon'>
                        <BiDuplicate />
                    </div>
                    <p>Añadir Documentos</p>
                    {/* <Image  style={{width: 30, height: 30}} source={require('../../../public/img/customer.png')}  /> */}
                    {/* <img src={"../../../public/img/customer.png"} /> */}
                    
                </div>
            </Link>
        
            <Link to="../customerManagement">
                <div className='optionsMenu'>
                    <div className='icon'>
                        <FaUserFriends />
                    </div>
                    <p>Gestión de Clientes</p>
                </div>
            </Link>
            <Link to="/Reporting">
                <div className='optionsMenu'>
                    <div className='icon'>
                        <FaPoll />
                    </div>
                    <p>Reporting</p>
                </div>
            </Link>

            <UserContext.Consumer>
                {({user}) => {
                    if (user && user.is_admin ) return (
                        <Link to="../validatedocs">
                            <div className='optionsMenu'>
                                <div className='icon'>
                                    <BiUserCheck />
                                </div>
                                <p>Validación de clientes</p>
                            </div>
                        </Link>
                    )
                }}
            </UserContext.Consumer>
            <Link to="/FrequentQuestions">
                <div className='optionsMenu'>
                    <div className='icon'>
                        <FaQuestionCircle />
                    </div>
                    <p>Preguntas Frecuentes</p>
                </div>
            </Link>
        </div>
    
    </section>
        <UserContext.Consumer>
        {({user}) => {
            if (user && !user.is_admin && elements.length > 0) return (
                <div className='table'>
                    <EnhancedTable 
                        rows = {elements}
                        headCells = {headers}
                        title = {"Clientes con incidencias abiertas"}
                        actionFunction = {[markAsCompleted]}
                        actionName = {['Marcar como resuelta']}
                    />
                </div>
            )
        }}
        </UserContext.Consumer>
    </Fragment>
    )
}

export default Menu
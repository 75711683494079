import React, {Fragment, useState} from 'react';
import FormInput from '../FormInput';
import useForm from '../../hooks/useForm';
import {clienteAxiosNoJWT} from '../../config/axios'
import Alert from '@mui/material/Alert';
import './login.css'

const ForgotPassword = () => {

    const [sent, setSent] = useState(false);
    const [errorForm, setErrorForm] = useState(false)
    const { values, handleChange } = useForm({
        initialValues: {
            username: '',
        }
    });

    const handleLogin = async (e) => {
        e.preventDefault();
        if (errorForm) {setErrorForm(false)}
        if (values.username.length > 0) {
            setSent(true)
            await clienteAxiosNoJWT.post("/auth/requestreset", {
                email: values.username
            });
        } else {
            setErrorForm(true)
        }
    }

    return (
        <Fragment>
            {
                sent &&
                <section className="Login">
                    <div className="text-center">
                        <span>Consulte su bandeja de entrada</span>
                    </div>
                </section>
            }

            {   
                !sent &&
                    <form onSubmit={handleLogin}>
                        <section className="Login">
                            <h5>Introduzca su email</h5>
                            <div>
                                <FormInput
                                    type="text"
                                    name="username"
                                    value={values.username}
                                    placeholder = 'Usuario'
                                    handleChange={handleChange}
                                />
                            </div>
                            <div>
                                <span> 
                                    <div>
                                        <button className="btn" type='submit'>enviar</button>
                                    </div>
                                </span>
                            </div>
                            {
                                errorForm &&
                                <div style={{marginTop: 10}}>
                                    <Alert 
                                        severity="warning"
                                    >
                                        
                                    Campo vacío</Alert>
                                </div>
                            }
                        </section>
                    </form>
            }
            
        </Fragment>
    );
}

export default ForgotPassword
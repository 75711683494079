import { useState, useEffect } from 'react';
import {clienteAxios} from '../config/axios';
 
const useFetchApi = () => {
  const [elements, setElements] = useState([]);
  const [totalElements, setTotalElements] = useState(0);
  const [url, setUrl] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
 
  useEffect(() => {

    const fetchData = async () => {
        if (url.url) {
            setIsError(false);
            setIsLoading(true);
            try {
                const respuesta = await clienteAxios.get(url.url, {params: url.params});
                setElements(respuesta.data);
                setTotalElements(respuesta.data.length);
            } catch (error) {
                setIsError(true);
                console.log(error)
                setElements([])
            }
 
            setIsLoading(false);
        } else {
            setElements([]);
            setTotalElements(0);
        }
    };
 
    fetchData();
  }, [url]);
 
  return [{ elements, totalElements, isLoading, isError, setElements }, setUrl];
};

export default useFetchApi;

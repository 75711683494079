import React, {useState, useEffect} from 'react';
import Modal from "react-modal";
import useForm from '../../hooks/useForm';
import useSelector from '../../hooks/useSelector';
import { IoIosClose } from "react-icons/io";
import {clienteAxios} from '../../config/axios';
import {UserContext} from '../../hooks/UserContext';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

import "./modals.css"
var FormData = require('form-data');

const CarModal = ({isOpen, onRequestClose, id, onSave}) => {

    const [listaMarcas, setListaMarcas] = useState([])
	const [listaCoches, setListaCoches] = useState([]);
    const [listaEntity, setListaEntity] = useState([])
	const [listaCompany, setListaCompany] = useState([]);
    const [id_marca, SelectMarca, setIdMarca] = useSelector('', '', listaMarcas);
	const [id_coche, SelectCoche, setIdCoche] = useSelector('', '', listaCoches);
    const [id_entity, SelectEntity, setIdEntity] = useSelector('', '', listaEntity);
	const [id_company, SelectCompany, setIdCompany] = useSelector('', '', listaCompany);
    const [errors, setErrors] = useState([])

    const { values, handleChange, setValues } = useForm({
		initialValues: {
			plate: '',
            year: ''
		}
	});

    useEffect(() => {

        const getBrands = async () => {
            try {
                const result = await clienteAxios.get('compute/getBrands');
                setListaMarcas(result.data);
            } catch (error) {
                console.log(error);
            }
        };

        const getEntities = async () => {
            let urlLocalizacion1 = 'compute/getEntities';
            try {
                const entity = await clienteAxios.get(urlLocalizacion1);
                setListaEntity(entity.data);
                if (entity.data.length == 1) {
                    setIdEntity(entity.data[0].id)
                }

            } catch (error) {
                console.log(error);
                setListaEntity([]);
            }
        }

        const getCarData = async (id) => {
            let url = 'compute/getCarInfo/' + id;
            try {
                const request = await clienteAxios.get(url);
                const car = request.data
                setValues({
                    plate: car.plate,
                    year: car.year,
                    month: car.month,
                    chassis: car.chassis
                })
                setIdCoche(car.model_id)
                setIdCompany(car.company_id)
                setIdEntity(car.entity_id)
                
                setIdMarca(car.brand_id)
            } catch (error) {
                console.log(error);
                setListaEntity([]);
            }
        }
     
        getEntities()
        getBrands();

        if (id) {
            getCarData(id)
        }
    }, [])

    useEffect(() => {
        const getModels = async () => {
            try {
                const result = await clienteAxios.get('compute/getModels/' + id_marca);
                setListaCoches(result.data);
            } catch (error) {
                console.log(error);
            }
        };
  
		if (id_marca.length !== 0) {
            getModels()
		}
  
	}, [id_marca]);

    //lista de compañias
	useEffect(() => {

		const getCompanies = async () => {
            let urlLocalizacion1 = `compute/getEntityCompanies/${id_entity}`;
            try {
                const companies = await clienteAxios.get(urlLocalizacion1);
                setListaCompany(companies.data);
            } catch (error) {
                console.log(error);
                setListaCompany([]);
            }
        }

        if (id_entity.length !== 0) getCompanies()
	
	}, [id_entity]);
    
    const handleClose = () => { onRequestClose(); }

    const handleValidation = () => {
        let errors = [];
        let formIsValid = true;
    

        if (! (values.plate && values.year && values.month && values.chassis && id_company && id_coche)) {
            formIsValid = false;
            errors.push("Todos los campos son obligatorios");
        }

        if (!(values.plate.match(/^[0-9]{4}[BCDFGHJKLMNPRSTVWXYZ]{3}/) || values.plate.match(/^[A-Z]{1,2}[0-9]{4}[A-Z]{1,2}/))) {
            formIsValid = false;
            errors.push("Formato incorrecto de matrícula");
        }

        setErrors(errors)
   
        return formIsValid;
    }

    const handleCreate = (e) => {
        e.preventDefault();
        let valid = handleValidation()

        if (valid) {
            var data = new FormData();
            Object.entries(values).forEach(([key, value]) => {
                data.append(key, value);
            });

            if (id) data.append('id', id)
            data.append('company_id', id_company)
            data.append('model_id', id_coche)

            var config = {
                method: 'post',
                url: '',
                data : data
            };
            
            config.url = id ? 'compute/updateCar' :  'compute/createCar'
            
            clienteAxios(config)
                .then((result) => {
                    alert('Elemento guardado');
                    onSave(true)
                    handleClose()
            })
            .catch((error) => {
                alert('Se ha producido un error');
                console.log(error);
            })
        }
        
    }

    return (  
        <Modal isOpen={isOpen} onRequestClose={handleClose} >
            <form className='formModal' onSubmit={handleCreate}>
                <div className='closeIcon'>
                    <IoIosClose onClick={handleClose} />
                </div>
        
                <div className='modalContainer'>
                    <h2>{id ? 'Editar' : 'Añadir'} vehículo</h2>

                    {
                        errors.length > 0 && 
                        <Stack sx={{ width: '100%' }} spacing={1}>
                            {
                                errors.map((error) => {
                                    return (
                                        <Alert severity="error">{error}</Alert>
                                    )
                                })
                            }
                        </Stack>
                    }

                    <div>
                        <label>Organización</label>
                        <Grid item><div ><SelectEntity/></div></Grid>
                    </div>

                    <div>
                        <label>Empresa</label>
                        <Grid item><div ><SelectCompany/></div></Grid>
                    </div>

                    <div>
                        <label>Marca</label>
                        <Grid item><div ><SelectMarca/></div></Grid>
                    </div>

                    <div>
                        <label>Modelo</label>
                        <Grid item><div ><SelectCoche/></div></Grid>
                    </div>
                    <div>
                        <label>Matrícula</label>
                        <input 
                            type="text"
                            name="plate"
                            maxlength="10"
                            className="form-control"
                            onChange={handleChange}
                            value={values.plate}>
                        </input>
                    </div>

                    <div>
                        <label>Número de bastidor</label>
                        <input 
                            type="text"
                            name="chassis"
                            className="form-control"
                            onChange={handleChange}
                            value={values.chassis}>
                        </input>
                    </div>
                    
                    <div>
                        <label>Año de compra</label>
                        <select id="year" defaultValue={''} name ="year" onChange={handleChange} value={values.year}>
                            <option value='' disabled="disabled">---</option>
                            <option value="1997">1997</option>
                            <option value="1998">1998</option>
                            <option value="1999">1999</option>
                            <option value="2000">2000</option>
                            <option value="2001">2001</option>
                            <option value='2002'>2002</option>
                            <option value="2003">2003</option>
                            <option value="2004">2004</option>
                            <option value='2005'>2005</option>
                            <option value='2006'>2006</option>
                            <option value="2007">2007</option>
                            <option value="2008">2008</option>
                            <option value="2009">2009</option>
                            <option value='2010'>2010</option>
                            <option value="2011">2011</option>
                            <option value="2012">2012</option>
                            <option value='2013'>2013</option>
                            <option value="2014">2014</option>
                            <option value='2015'>2015</option>
                            <option value='2016'>2016</option>
                            <option value="2017">2017</option>
                            <option value="2018">2018</option>
                            <option value="2019">2019</option>
                            <option value='2020'>2020</option>
                            <option value="2021">2021</option>
                            <option value="2022">2022</option>
                            
                        </select>
                    </div>
                    <div>
                        <label>Mes de compra</label>
                        <select id="month" defaultValue={''} name ="month" onChange={handleChange} value={values.month}>
                            <option value='' disabled="disabled">---</option> 
                            <option value='1'>Enero</option>
                            <option value="2">Febrero</option>
                            <option value="3">Marzo</option>
                            <option value="4">Abril</option>
                            <option value='5'>Mayo</option>
                            <option value="6">Junio</option>
                            <option value="7">Julio</option>
                            <option value='8'>Agosto</option>
                            <option value='9'>Septiembre</option>
                            <option value='10'>Octubre</option>
                            <option value='11'>Noviembre</option>
                            <option value='12'>Diciembre</option>
                            
                        </select>
                    </div>
                </div>
                
                <div className='addModalBtn'>
                    <button className='btn' type='submit'>{id ? 'Actualizar' : 'Añadir'}</button>
                </div>
            </form>
        </Modal>
    );
}

 
export default CarModal;

import React, { useMemo, Fragment } from 'react';
import useFetchApi from '../../hooks/useFetchApi';
import PageTable from '../../UI/BaseTable/PageTable'
import useForm from '../../hooks/useForm';

const ValidateDocsList = () => {
	const [{ elements, totalElements, isLoading, isError }, doFetch] = useFetchApi();

	const { values, handleChange } = useForm({
		initialValues: {
			name: '',
			superentity: '',
			is_verified: '',
            id_number: ''
		}
	});

	useMemo(() => {
		doFetch({url: 'compute/getClosedCompanies', params: values})
	}, [])

	const handleSearch = async (e) => {
        e.preventDefault();
        doFetch({url: 'compute/getClosedCompanies', params: values})
    }

    const headers = [
        { 
            id: 'name', 
            label: 'Nombre', 
            minWidth: 100,
            link: 'validatedocs'
        },
        {
            id: 'entity',
            label: 'Organización',
            minWidth: 105,
            align: 'right',
            
        },
        {
            id: 'super',
            label: 'Asociación',
            minWidth: 105,
            align: 'right',
        },
        {
            id: 'id_number',
            label: 'CIF',
            minWidth: 90,
            align: 'right',
        },
        {
            id: 'is_verified',
            label: 'Verificado',
            minWidth: 40,
            align: 'right'
        },
    ];

	return (
		<Fragment>		
			<div className='containerTable'>
          		<div className='headerSearch'>
					<div>
					<h3>Buscador de empresas:</h3>
					</div>
          	    </div>
			  
			    <form onSubmit={handleSearch}>
                    <div className='search'>
                        <div>
                            <label>Nombre empresa</label>
                            <input 
                                type="text"
                                name="name"
                                className="form-control"
                                onChange={handleChange}
                                value={values.name}
                            />
                        </div>
                        <div>
                            <label>Asociación</label>
                            <input 
                                type="text"
                                name="superentity"
                                className="form-control"
                                onChange={handleChange}
                                value={values.superentity}
                            />
                        </div>
                        <div>
                            <label>NIF</label>
                            <input 
                                type="text"
                                name="id_number"
                                className="form-control"
                                onChange={handleChange}
                                value={values.id_number}
                            />
                        </div>

                        <div>
						<label>Verificado</label>
						<select id="is_verified" name ="is_verified" onChange={handleChange} value={values.is_verified}>
							<option value="">Todos</option>
							<option value="True">Sí</option>
							<option value="False">No</option>
						</select>
					</div>
                    </div>
                    <div className='searchBtn'>
                        <button className='btn' type='submit'>Buscar</button>
                    </div>
                </form>
            </div>

			{isError && <div>Error al obtener datos de la base de datos</div>}

			{
				isLoading &&
					<div className="text-center">
						<div className="spinner-border" role="status">
							<span className="sr-only">Loading...</span>
						</div>
					</div>
			}
				
			{ !isError && !isLoading && elements.length > 0 &&
				<div className='table'>
                    <PageTable 
                        headers = { headers } 
                        elements = { elements }
                    />
				</div>
			}
			
			</Fragment>
		);
	};


export default ValidateDocsList
import React, { useEffect, useState, useMemo, Fragment } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {clienteAxios} from '../config/axios'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';

const FileCard = ({car, document_type_name, document_type_id, file, company}) => {

    const myValueFromProp = file;
    const [uploadedFile, setFile] = useState(myValueFromProp)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setFile(file);
     }, [file])

    const uploadFile = ({ target: { files } }) => {
        setLoading(true);
        console.log( files[0] )
        let data = new FormData();
        data.append('file', files[0] )
        if (car) data.append('car', car)
        if (company) data.append('company', company)
        data.append('document_species', document_type_id)
    
    
        clienteAxios.post("compute/uploadFiles", data)
            .then(res => {
                const id = res.data.msg
                setFile(uploadedFile => [...uploadedFile, {id: id, name: files[0].name + " (Subido ahora mismo)"}])
            })
            .catch(function (error) {
                console.log(error);
                alert('Error al subir el fichero')
            })
            .finally(function () {
                setLoading(false)
            });
    }

    const deleteFile = (id) => {       
        let config = {
            method: 'delete',
            url: 'compute/deleteFile/' + id
        }
          
        clienteAxios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
                setFile(uploadedFile.filter(item => item.id !== id));
            })
            .catch(function (error) {
                console.log(error);
                alert('Error al borrar el fichero')
            });
          
    }

    return (
        <Fragment>
		<Box  className="fileCard">
            <Card variant="outlined">
                <CardContent className="CardContent">
                    <Typography variant="h5" component="div">
                        {document_type_name}
                    </Typography>
                    
                    {
                        uploadedFile && 
                        <ul>
                            <Typography variant="small" component="div">
                                {
                                    uploadedFile.map(file => (
                                        <li>
                                            <small>{file.name}</small>
                                            <div className='deleteFile' type='button' onClick={() =>deleteFile(file.id)}>
                                                <div><DeleteForeverIcon/></div> 
                                            </div>
                                        </li>
                                    ))
                                }
                            </Typography>
                        </ul>
                    }
                   
                </CardContent>
                <CardActions className="CardActions">
                    <Fragment >
                        <input
                            type="file"
                            accept="image/*,.pdf"
                            style={{ display: 'none' }}
                            id={"contained-button-file-" + document_type_id}
                            onChange={uploadFile}
                        />
                        <label htmlFor={"contained-button-file-" + document_type_id}>
                        <Box sx={{ m: 1, position: 'relative' }}>
                            <Button variant="contained" color="primary" component="span" disabled={loading}>
                                Subir fichero {document_type_name}
                            </Button>

                            {loading && (
                                <CircularProgress
                                size={24}
                                sx={{
                                    color: green[500],
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                                />
                            )}
                            </Box>
                        </label>
                    </Fragment>
                </CardActions>
            </Card>
        </Box>
        </Fragment>
	  );
	};


export default FileCard
import React, {Fragment} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import SignOut from '../../UI/SignOut/SignOut';
import { Outlet } from 'react-router-dom';
import {UserContext} from '../../hooks/UserContext';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";
import {Home} from '@mui/icons-material/';
import { FaUserFriends,FaRegCopy, FaPoll, FaQuestionCircle } from "react-icons/fa";
import { BiDuplicate, BiUserCheck } from "react-icons/bi";


export default function MenuBar() {
/*
	const [anchorEl, setAnchorEl] = React.useState(null);
	const handleMenu = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};
*/

	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<Fragment >
			<Box sx={{ flexGrow: 1 }} className="menuBar">
				<AppBar position="static">
				<Toolbar>
					<Button id="btn_home" variant="text" color="inherit" href="/">
						<Home fontSize="medium" />  
					</Button>
					<IconButton
						size="large"
						edge="start"
						color="inherit"
						aria-label="menu"
						sx={{ mr: 2 }}
						onClick={handleClick}
					>
					<MenuIcon />
					</IconButton>
					<Menu
						id="long-menu"
						MenuListProps={{
							'aria-labelledby': 'long-button',
						}}
						anchorEl={anchorEl}
						open={open}
						onClose={handleClose}
						className ="menuBarMenu"
						/*PaperProps={{
							style: {
								maxHeight: ITEM_HEIGHT * 4.5,
								width: '20ch',
							},
						}}*/
					>
						
						<MenuItem key={'docs'} onClick={handleClose}>
							<Button variant="text" size="small">
								<Link to="../documents"><FaRegCopy /><p>Documentos</p>
								</Link>
							</Button>
						</MenuItem>

						<MenuItem key={'add'} onClick={handleClose}>
							<Button variant="text" size="small">
								<Link to="../add-documents"><BiDuplicate /><p>Añadir documentos</p>
								</Link>
							</Button>
						</MenuItem>

						<MenuItem key={'customer'} onClick={handleClose}>
							<Button variant="text" size="small">
								<Link to="../customerManagement"><FaUserFriends /><p>Gestión de clientes</p>
								</Link>
							</Button>
						</MenuItem>
						<MenuItem key={'qa'} onClick={handleClose}>
							<Button variant="text" size="small">
								<Link to="../reporting"><FaPoll /><p>Reporting</p>
								</Link>
							</Button>
						</MenuItem>
						<UserContext.Consumer>
							{({user}) => {
								if (user && user.is_admin ) return (
									<MenuItem key={'validate'} onClick={handleClose}>
									<Button variant="text" size="small">
										<Link to="../validatedocs"><BiUserCheck /><p>Validación de clientes</p>
										</Link>
									</Button>
									</MenuItem>
								)
							}}
            			</UserContext.Consumer>
						<MenuItem key={'qa'} onClick={handleClose}>
							<Button variant="text" size="small">
								<Link to="../frequentQuestions"><FaQuestionCircle /><p>Preguntas frecuentes</p>
								</Link>
							</Button>
						</MenuItem>
						
					</Menu>
					<Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
					Plataforma de vehículos
					</Typography>
					<Typography variant="h7" component="div" align="right" >
						<UserContext.Consumer>
							{({user}) => {
								if (user) return (<span style={{marginRight: '15px'}}>{user.email}</span>)
							}}
						</UserContext.Consumer>
					</Typography>
					<SignOut/>
				</Toolbar>
				</AppBar>
			</Box>

			<Outlet />
		</Fragment>
	);
}
